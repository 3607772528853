import { FETCH_FUNCTIONALROLES } from "../actionTypes";
import { fetchFunctionalRoles } from "../../../api/userManagement/userManagement.service";

const request = () => ({ type: FETCH_FUNCTIONALROLES.REQUEST });
const failure = (error) => ({ type: FETCH_FUNCTIONALROLES.FAILURE, error });
const success = (functionalRoles) => ({ type: FETCH_FUNCTIONALROLES.SUCCESS, functionalRoles});

export const functionalRoles = () => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchFunctionalRoles()
      .then((res) => {
        return dispatch(success(res));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };
};
