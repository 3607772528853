import { Frequency} from 'rrule'

export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const SMS_REGEX = /^[0-9]{3,25}$/g

export const SIDE_BAR_MENU_ITEMS = {
    REPORTS: "reports",
    USER_MANAGEMENT: "user-management",
    NEWS: "news",
    EVENTS: "events",
    CONFIGURATION: "configuration",
    SERVICES : "services",
    INCIDENT : "incident",
    ARTICLE:'article',
    ARCHIVED_INCIDENTS : "archived-incidents",
    INFORMATION : 'information'
}

export const ERRORS={
    TOO_MANY_REQUEST:'too-many-requests',
    USER_NOT_FOUND:'user-not-found',
    WRONG_PASSWORD:'wrong-password',
    INVALID_EMAIL:'invalid-email',
    INTERNAL_ERROR:'internal-error'
}

export const ADMIN = {
    Content: "content",
    Reports: "reports",
    App: "app-admin"
}

export const EVENT_LOCATION_TYPE = {
    InPerson:  "in_person",
    Online: "online"
}

export const VALIDATION_ERROR_MESSAGE = {
    Required : "Required",
    Invalid_Email : "Invalid email address",
    Invalid_Url : "Invalid url",
    Invalid_Phone: "Invalid phone number",
    Invalid_SMS: "Invalid sms number"
}

export const SUPPORT_SERVICE_TYPE = {
    Advice : "advice",
    Hate_Crime : "hate-crime",
    Housing : "housing",
    Mental_Health: "mental-health",
    Other : "other",
    Sexual_Health: "sexual-health"
}

export const ROLES = {
    ADMIN: "admin",
    COUNTRY_REPORT_ANALYST: "country_report_analyst",
    GLOBAL_REPORT_ANALYST: "global_report_analyst",
    GLOBAL_CONTENT_MANAGER: "global_content_manager",
    LOCAL_CONTENT_MANAGER: "local_content_manager",
    CSO_INCIDENT_MANAGER: "cso_incident_manager",
};

export const  IDLE_TIMEOUT = 600;

export const TIMES_ZONES = {
    gbr : "Europe/London", //UK
    alb: "Europe/Tirane", //Albania
    cze: "Europe/Prague" //Czech Republic (Czechia)
}

export const FREQUENCY = {
    DAILY: Frequency.DAILY,
    WEEKLY: Frequency.WEEKLY,
    MONTHLY: Frequency.MONTHLY,
    YEARLY: Frequency.YEARLY
}

export const DATE_FORMAT = "dd MMM yyyy"

