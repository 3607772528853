const types = [
    {label: "Advice", value: "advice"},
    {label: "Hate crime", value: "hate-crime"},
    {label: "Housing", value: "housing"},
    {label: "Mental health", value: "mental-health"},
    {label: "Other", value: "other"},
    {label: "Sexual health", value: "sexual-health"},
]

export const get = ()=>{
    return Promise.resolve(types)
}