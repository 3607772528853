import { FETCH_CITIES, FETCH_EVENTS } from "../actionTypes";
import { fetchEvents, fetchEventsLocation } from "../../../api/events/events.service";

const request = ()=>({type: FETCH_EVENTS.REQUEST});
const failure = (error)=>({type: FETCH_EVENTS.FAILURE, error})
const success = (obj)=>({type: FETCH_EVENTS.SUCCESS, obj})

const citiesRequest = ()=>({type: FETCH_CITIES.REQUEST});
const citiesFailure = (error)=>({type: FETCH_CITIES.FAILURE, error})
const citiesSuccess = (obj)=>({type: FETCH_CITIES.SUCCESS, obj})



export const getEvents = (isLoadMore,next)=>{
    return (dispatch, getState)=>{
        dispatch(request())
        return fetchEvents(next)
        .then(events => {
            let obj={
                'data':events,
                'isLoadMore':isLoadMore
            }
            dispatch(success(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(failure(error))
            return Promise.resolve(false)
        })
    }
}


export const getCities = (city)=>{
    return (dispatch, getState)=>{
        dispatch(citiesRequest())
        return fetchEventsLocation(city)
        .then(({cities}) => {
            dispatch(citiesSuccess(cities))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(citiesFailure(error))
            return Promise.resolve(false)
        })
    }
}

