import React, { useState, useEffect } from 'react';
import styles from "./imageDropZone.module.css";
import {ButtonSpinner} from "../loader/Loaders";

const ImageDrop = ({upload, onUpload})=>{
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validFiles, setValidFiles] = useState([]);
    const [uploading, setUploading] = useState(false)

    useEffect(() => {


        let filteredArray = selectedFiles.reduce((files, current) => {

            if(validateFile(current)){
                return [...files, current]
            }
            return files
            // const x = file.find(item => item.name === current.name);
            // if (!x) {
            //     return file.concat([current]);
            // } else {
            //     return file;
            // }
        }, []);
        setValidFiles([...filteredArray]);
    
    }, [selectedFiles]);

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const handleFiles = (files) => {
        // for(let i = 0; i < files.length; i++) {
            if (validateFile(files[0])) {
                // add to an array so we can display the name of file
                /**
                 * For multiple files save old files too like as below
                 * [files[0], ...prevArray]
                 */
                setSelectedFiles(prevArray => [files[0]]);
            } else {
                // add a new property called invalid
                files[0]['invalid'] = true;
                // add to the same array so we can display the name of the file
                setSelectedFiles(prevArray => [files[0]]);
                // set error message
                setErrorMessage('File type not permitted');
            }
        // }
    }
    
    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = (name) => {
        // find the index of the item
        // remove the item from array
    
        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
        // update validFiles array
        setValidFiles([...validFiles]);
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array
        setSelectedFiles([...selectedFiles]);
    }

    const uploadFiles = () => {
        if(!validFiles[0]){
            return false
        }
        setUploading(true)
        upload({file: validFiles[0], name: validFiles[0].name})
        .then((url)=>{
            setUploading(false)
            onUpload({error: null, url : encodeURI(url), message: "Image uploaded successfully"})

        })
        .catch(error => {
            setUploading(false)
            onUpload({error, url: null, message: error.message})
        })
    }

    if(uploading){
        return <ButtonSpinner />
    }

    //something fishy this render happens mulitple times when user types in any text field in the form
    console.warn("multiple renders")

    const enableUpload = (validFiles.length > 0) ? true : false
    const uploadButtonStyle = enableUpload ? styles.fileUploadBtn : `${styles.fileUploadBtn} ${styles.fileUploadBtnDisabled}`

    return (
        <div className={styles.container}>

            
            <div 
                className={styles.imageUploader}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
            >
                Drop an image to upload
            </div>

            <div className={styles.noteContainer}>
                <label><span>Note:</span> Maximum image size allowed is 1Mb. Images only in jpeg, jpg and png formats are allowed.</label>
            </div>

            

            <div className={styles.fileDisplayContainer}>
                {
                    selectedFiles.map((data, i) => {

                        if(i !== 0){
                            return null
                        }else{
                            return (
                                <div className={styles.fileStatusBar} key={data.name}>
                                    <div>
                                        <div className={styles.fileTypeLogo}></div>
                                        <div className={styles.fileType}>{fileType(data.name)}</div>
                                        <span className={`${styles.fileName} ${data.invalid ? styles.fileError : ''}`}>{data.name}</span>
                                        <span className={styles.fileSize}>({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                                    </div>
                                    <div className={styles.fileRemove} onClick={() => removeFile(data.name)}>X</div>
                                </div>
                            );
                        }

                       }
                    )
                }
            </div>

            <button disabled={!enableUpload} type='button' className={uploadButtonStyle} onClick={() => uploadFiles()}>Click To Upload</button>
        </div>
    );
}

export default ImageDrop;