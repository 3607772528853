import {FETCH_INCIDENT,UPDATE_INCIDENT, ARCHIVE_INCIDENT, FETCH_ARCHIVED_INCIDENT, UNARCHIVE_INCIDENT} from "../actionTypes"
import {fetchIncident,updateIncident, archiveIncident, unarchiveIncident} from "../../../api/incidents/incidents.service"

const incidentRequest = ()=>({type: FETCH_INCIDENT.REQUEST})
const incidentFailure = (error)=>({type: FETCH_INCIDENT.FAILURE, error})
const incidentSuccess = (incident)=>({type: FETCH_INCIDENT.SUCCESS, incident})

export const getIncident = (incident_id)=>{
    return (dispatch, getState)=>{
        dispatch(incidentRequest())
        return fetchIncident(incident_id)
        .then(response => {
            dispatch(incidentSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(incidentFailure(error))
            return Promise.resolve(false)
        })
    }
}

const updateRequest = ()=>({type: UPDATE_INCIDENT.REQUEST})
const updateFailure = (error)=>({type: UPDATE_INCIDENT.FAILURE, error})
const updateSuccess = (incident)=>({type: UPDATE_INCIDENT.SUCCESS, incident})

export const updateReviewStatus = (payload,incident_id)=>{
    return (dispatch, getState)=>{
        dispatch(updateRequest())
        return updateIncident(payload,incident_id)
        .then(response => {
            dispatch(updateSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(updateFailure(error))
            return Promise.resolve(false)
        })
    }
}

const archiveRequest = ()=> ({type: ARCHIVE_INCIDENT.REQUEST})
const archiveFailure = (error)=>({type: ARCHIVE_INCIDENT.FAILURE, error})
const archiveSuccess = ()=>({type: ARCHIVE_INCIDENT.SUCCESS})

export const archive = (id)=>{
    return (dispatch, getState)=>{
        dispatch(archiveRequest())
        return archiveIncident(id)
        .then(response => {
            dispatch(archiveSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(archiveFailure(error))
            return Promise.resolve(false)
        })
    }
}

const archivedIncidentRequest = ()=>({type: FETCH_ARCHIVED_INCIDENT.REQUEST})
const archivedIncidentFailure = (error)=>({type: FETCH_ARCHIVED_INCIDENT.FAILURE, error})
const archivedIncidentSuccess = (incident)=>({type: FETCH_ARCHIVED_INCIDENT.SUCCESS, data: incident})
export const getArchivedIncident = (incident_id)=>{
    return (dispatch, getState)=>{
        dispatch(archivedIncidentRequest())
        return fetchIncident(incident_id)
        .then(response => {
            dispatch(archivedIncidentSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(archivedIncidentFailure(error))
            return Promise.resolve(false)
        })
    }
}

const unarchiveRequest = ()=> ({type: UNARCHIVE_INCIDENT.REQUEST})
const unarchiveFailure = (error)=>({type: UNARCHIVE_INCIDENT.FAILURE, error})
const unarchiveSuccess = ()=>({type: UNARCHIVE_INCIDENT.SUCCESS})

export const unarchive = (id)=>{
    return (dispatch, getState)=>{
        dispatch(unarchiveRequest())
        return unarchiveIncident(id)
        .then(response => {
            dispatch(unarchiveSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(unarchiveFailure(error))
            return Promise.reject(false)
        })
    }
}