import {ADD_INFORMATION, PUBLISH_INFORMATION } from "../actionTypes";
import {
  addInformation as addInformationService,
  updateInformation as updateInformationService,
  publishInformation as publishInformationService,
} from "../../../api/information/information.service";

const addInformationRequest = ()=>({type: ADD_INFORMATION.REQUEST})
const addInformationFailure = (error)=>({type: ADD_INFORMATION.FAILURE, error})
const addInformationSuccess = ()=>({type: ADD_INFORMATION.SUCCESS})

export const addInformation = (payload)=>{
    return (dispatch, getState)=>{
        dispatch(addInformationRequest())
        return addInformationService(payload)
        .then(response => {
            dispatch(addInformationSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(addInformationFailure(error))
            return Promise.resolve(false)
        })
    }
}


const publishInformationRequest = ()=>({type: PUBLISH_INFORMATION.REQUEST})
const publishInformationFailure = (error)=>({type: PUBLISH_INFORMATION.FAILURE, error})
const publishInformationSuccess = ()=>({type: PUBLISH_INFORMATION.SUCCESS})

export const publishInformation = (payload, informationId)=>{
    return (dispatch, getState)=>{
        dispatch(publishInformationRequest())
        return publishInformationService(payload, informationId)
        .then(response => {
            dispatch(publishInformationSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(publishInformationFailure(error))
            return Promise.resolve(false)
        })
    }
}