import {
    START_EDITING_USER,
    FETCH_USERS,
    EDIT_USER,
    CANCEL_EDIT_USER
  } from "../../actions/actionTypes";
  
  const initialState = {
    isEditMode: false,
    details: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case START_EDITING_USER:
        return { ...state, isEditMode: true, details: action.user };
      case FETCH_USERS.REQUEST:
        return { ...state, isEditMode: false, details: null };
      case CANCEL_EDIT_USER.REQUEST:
      case EDIT_USER.SUCCESS:
        return { ...state, isEditMode: false, details: null };
      default:
        return state;
    }
  };
  export default reducer;