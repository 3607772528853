import Select from 'react-select'

const dropdownStyle = {
    control: (provided, state)=> {
        const {selectProps: {hasError}, isDisabled} = state
       
        return (
            {...provided, backgroundColor: isDisabled ? "#999" : "#efefef", borderRadius: 5, height: 40, borderWidth: 1, borderColor: hasError? "#CD3500" : "#C5C1C8",width:"600px"}
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#100045", fontFamily: "Moderat-Regular", fontSize: 13}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#837B8B", fontFamily: "Moderat-Regular", fontSize: 13}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    container: (provided, state)=> ({...provided, width: 500,}),
}

const DropDown = ({options, onChange,value, hasError})=>{
    
    return (
        <Select 
            options={options}
            onChange={onChange}
            styles={dropdownStyle}
            hasError={hasError}
            value={value}/>
    );
}

export default DropDown;