import {SUPPORT_SERVICE_TYPE} from "../../actions/actionTypes"

const reducer = (state=[], action)=>{
    switch(action.type){
        case SUPPORT_SERVICE_TYPE.SUCCESS:
            return action.serviceTypes;
        default:
            return state
    }
}

export default reducer;