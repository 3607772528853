import {connect} from "react-redux"
import ArchivedIncident from "./ArchivedIncident"
import {getArchivedIncident, unarchive} from "../../../redux/actions/incidents/incident.action"

const mapStateToProps = (state)=>{
    return {
        incident : state.incidents.archivedIncident.data,
        loading: state.incidents.archivedIncident.loading
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        get: (incidentId)=>{
            return dispatch(getArchivedIncident(incidentId))
        },
        unarchive: (id)=>{
            return dispatch(unarchive(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedIncident)