import React, { Component } from "react";
import { useState } from "react";
import { ButtonPrimary } from "../../components/button/buttons";
import { useNavigate } from "react-router-dom";
import styles from "../login/login.module.css";
import { sendEmailVerification } from "firebase/auth";
import { logOut } from "../../authActions/authActions";
import {ButtonSpinner} from "../../components/loader/Loaders";
import { getAuth } from "firebase/auth";
import { app } from '../../configuration/gcp-identity-platform-config';
import {getErrorMessage} from '../../utils/errors';

const ValidateEmail = (props) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [disbaleButton, setDisbaleButton] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const evokeEmailValidation = () => {
    return new Promise((resolve, reject) => {
      setShowLoader(true);
      setDisbaleButton(true);
      const auth = getAuth(app);
      
     sendEmailVerification(auth.currentUser)
     .then((userCredential) => {
          setShowMessage(true);
          setShowLoader(false);
          setTimeout(() => {
            setDisbaleButton(false);
          }, 15000)
          
    })
    .catch((error) => {
      setDisbaleButton(false);
      setShowErrorMessage(true);
      const errorCode=error.code.split('/')[1];
      const errorMsg=getErrorMessage(errorCode);
      reject(errorMsg);
    });



    })
  };
  
  return (
   <div className={styles.loginContainer}>
     <div className={styles.loginSection}>
      <div className={styles.logintitle}>
        <h2>Email verification pending:</h2>
      </div>
      
        <ButtonPrimary
            onClick={() => {
              logOut()
              .then((res) => {
                navigate("/login");
                window.location.reload();
              })
              .catch((error) => {
              });
            }}
          >
          Already validated!! please login
        </ButtonPrimary>
        <ButtonPrimary isDisabled={disbaleButton}
            onClick={() => {
              evokeEmailValidation();
            }}
          >
          Get email validation link
        </ButtonPrimary>
        {showLoader ? (
          <div className={styles.loader}>
            <ButtonSpinner />
          </div>
        ) : (
          <></>
        )}
        {showMessage ? (
          <div className={styles.loader}>
            Verification email sent to your email address
          </div>
        ) : (
          <></>
        )}
        {showErrorMessage ? (
          <div className={styles.loader}>
            Did not recieve verification email ? try again!!
          </div>
        ) : (
          <></>
        )}
     </div>
     </div>
  );

}
export default ValidateEmail;