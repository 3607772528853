import {
  BASE_URL,
  GET,
  IAM_ROLESMASTER,
  IAM_DATAGROUP,
  IAM_FUNCTIONALROLES,
  RIGHTS,
  RIGHTS_ME,
  POST,
  USERS,
  DELETE,
  PATCH
} from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import {getAccessToken} from "../../authActions/authActions";


export const AddUser = async (payload) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${USERS}`;

  return fetch(resource, {
    method: POST,
    headers: {
      "Content-Type": "application/json",
      "userauth":`bearer ${token}`
    },
    body: JSON.stringify(payload),
  })
    .then(statusParsing)
    .then(jsonParsing);
};


export const fetchUsers = async(next) => {
  const token = await getAccessToken();
  let resource = `${BASE_URL}${RIGHTS}`;
  if(next){
    resource=`${resource}?next=${next}`
}
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userauth":`bearer ${token}`
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const fetchFunctionalRoles = async() => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${IAM_FUNCTIONALROLES}`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userauth":`bearer ${token}`
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const fetchRolesMaster = async() => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${IAM_ROLESMASTER}`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userauth":`bearer ${token}`
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const fetchDataGroup = async() => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${IAM_DATAGROUP}`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userauth":`bearer ${token}`
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const fetchMyRights = async() => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${RIGHTS_ME}`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userauth" : `Bearer ${token}`
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const deleteUser = async(uid)=>{
  const token = await getAccessToken();
  const resource = `${BASE_URL}${USERS}/${uid}`
  return fetch(resource, {
      method: DELETE,
      headers: {
          "Content-Type" : "application/json",
          "userauth":`bearer ${token}`
      }
  })
  .then(statusParsing)
  .then(jsonParsing)
}

export const editRights = async(payload, uid)=>{
  const token = await getAccessToken();
  const resource = `${BASE_URL}${RIGHTS}/${uid}`
  return fetch(resource, {
      method: PATCH,
      headers: {
          "Content-Type" : "application/json",
          "userauth":`bearer ${token}`
      },
      body: JSON.stringify(payload)
  })
  .then(statusParsing)
  .then(jsonParsing)
}
