import {INCIDENTS_REPORT} from "../../actions/actionTypes"
import {GRAPH_TYPE} from "../../actions/reports/incidents.actions"

const initialState = {
    totalReports : 0,
    victim : [],
    time: [],
    pod : [],
    county: [],
    town: [],
    incidentType : [],
    familiarity : [],
    relation : [],
    motivation : [],
    consequence : [],
    incidentsReportLoading:true,
    seekingSupport : 0
}


const extractReports = (incidents, graphType,totalCalls,currentIndex, state)=>{
    let stateSlice = {...state, [graphType] : incidents}
    if(graphType === GRAPH_TYPE.VICTIM){
        const count  = incidents.reduce((agg, incident)=>{
            return incident.count + agg
        }, 0)
        stateSlice = {...stateSlice, totalReports : count}
    }else if(graphType === GRAPH_TYPE.SEEKING_SUPPORT){
        if(incidents){
            const result = incidents.find((data)=> data.can_contact == true)
            if(result){
                stateSlice = {...stateSlice, seekingSupport: result.count}
            }
        }
    }

    if(totalCalls===currentIndex+1){
        stateSlice = {...stateSlice, incidentsReportLoading:false}
    }
    return stateSlice
}

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case INCIDENTS_REPORT.REQUEST:
            return { ...state, incidentsReportLoading: true };            
            break;
        case INCIDENTS_REPORT.SUCCESS:
            return extractReports(action.incidents, action.graphType,action.totalCalls,action.currentIndex, state)
            break;

        default:
            return state; 
    }
}

export default reducer;