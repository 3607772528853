import {DEMOGRAPHIC_REPORT} from "../../actions/actionTypes"

const initialState = {
    gender : [],
    transHistory : [],
    ethnincity: [],
    age : [],
    sexualOrientation:[],
    loading:true
}

const extractReports = (incidents, graphType,totalCalls,currentIndex, state)=>{
    let stateSlice = {...state, [graphType] : incidents};
    if(totalCalls===currentIndex+1){
        stateSlice = {...state,[graphType] : incidents,loading:false};
    }

    return stateSlice
}

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case DEMOGRAPHIC_REPORT.REQUEST:
            return { ...state, loading: true };            
            break;

        case DEMOGRAPHIC_REPORT.SUCCESS:
            return extractReports(action.incidents, action.graphType,action.totalCalls,action.currentIndex, state)
            break;

        default:
            return state; 
    }
}

export default reducer;