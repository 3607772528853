import React, {Component} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/topbar/Header';
import Dashboard from './modules/dashboard/dashboard.container';
import Login from './modules/login/login.container';
import Sidebar from './components/sidebar/Sidebar';
import NewsFeed from './modules/news/list/NewsViewer.container';
import EventsViewer from './modules/events/viewer/eventsViewer.container';
import InformationViewer from './modules/informations/viewer/InformationViewer.container';
import NewInformation from './modules/informations/add/NewInformation.conatiner';
import Configuration from './modules/configuration/Configuration';
import NotFound from './modules/notFound/404';
import {connect} from "react-redux";
import {ADMIN} from "./utils/constants"
import NewEvent from './modules/events/add/NewEvent.container';
import  { Toaster } from 'react-hot-toast';
import AuthGaurd from "./gaurds/AuthGaurd";
import { app } from './configuration/gcp-identity-platform-config';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Services from './modules/supportServices/viewer/services.container';
import NewService from "./modules/supportServices/add/newService.container";
import UsersViewer  from './modules/userManagement/viewer/usersViewer.container';
import NewUser from './modules/userManagement/add/newUser.container';
import {defineRulesForUser} from "./casl/can";
import { getMyRights,updateMyRights} from './redux/actions/userManagement/myRights.action';
import RouteGaurd from "./gaurds/RouteGaurd";
import CAN from './casl/can';
import {Action , Resource} from './casl/constants';
import IncidentsViewer from './modules/incidents/viewer/incidentsViewer.container';
import Incident from "./modules/incidents/incident/incident.container";
import ValidateEmail from './modules/emailValidation/validateEmail';
import MFA from "./modules/mfa/multifactorEnabler.container"
import ArchivedIncidents from './modules/incidents/archivedIncidents/ArchivedIncidents.container';
import ArchivedIncident from './modules/incidents/archivedIncident/ArchivedIncident.container';
import NewsItem from './modules/news/item/NewsItem.container';


class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.props.getRights();
      } else {
        this.props.updateRights(null);
      }
    });
  }
  
  render() {
    const { role ,myRights,myRightsLoading } = this.props;
    // let IndexComponent = () => <Dashboard />;
    // if (role === ADMIN.Content) {
    //   IndexComponent = () => <EventsViewer />;
    // }
  
    defineRulesForUser(myRights?.permissions);
    return (
      <BrowserRouter>
        <AuthGaurd userDetails={myRights} isAppLoading={myRightsLoading}>
          <Header />
          <div className="content">
            <div className="pages">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/verifyemail" element={<ValidateEmail/>} />
                <Route path='/enable-mfa' element={<MFA />}/>
                <Route path="/" element={<Sidebar />}>
                  {/* <Route index element={<RouteGaurd><IndexComponent /></RouteGaurd>} /> */}
                  <Route path="dashboard" element={<RouteGaurd canAccess={CAN(Action.GET, Resource.REPORTS)}><Dashboard /></RouteGaurd>} />
                  <Route path="users" element={<RouteGaurd canAccess={CAN(Action.LIST, Resource.USERS) || CAN(Action.CREATE, Resource.USERS)}><UsersViewer /></RouteGaurd>} />
                  <Route path="news" element={<RouteGaurd canAccess={CAN(Action.LIST, Resource.FEED)}><NewsFeed /></RouteGaurd>} />
                  <Route path="news/:id" element={<RouteGaurd canAccess={CAN(Action.GET, Resource.FEED)}><NewsItem /></RouteGaurd>} />
                  <Route path="events" element={<RouteGaurd canAccess={CAN(Action.LIST, Resource.EVENT) || CAN(Action.CREATE, Resource.EVENT) || CAN(Action.GET, Resource.STATS)}><EventsViewer /></RouteGaurd>} />
                  {/* <Route path="configuration" element={<RouteGaurd canAccess={CAN(Action.LIST, Resource.USERS)}><Configuration /></RouteGaurd>} /> */}
                  <Route path="new-event" element={<RouteGaurd canAccess={CAN(Action.CREATE, Resource.EVENT)}><NewEvent /></RouteGaurd>} />
                  <Route path="services" element={<RouteGaurd canAccess={CAN(Action.LIST, Resource.SERVICES) || CAN(Action.CREATE, Resource.SERVICES)}><Services /></RouteGaurd>} />
                  <Route path="new-service" element={<RouteGaurd canAccess={CAN(Action.CREATE, Resource.SERVICES)}><NewService /></RouteGaurd>} />
                  <Route path="new-user" element={<RouteGaurd canAccess={CAN(Action.CREATE, Resource.USERS)}><NewUser /></RouteGaurd>} />
                  <Route path="incidents" element={<RouteGaurd canAccess={CAN(Action.LIST, Resource.INCIDENT)}><IncidentsViewer /></RouteGaurd>} />
                  <Route path="incidents/:id" element={<RouteGaurd canAccess={CAN(Action.GET, Resource.INCIDENT)}><Incident/></RouteGaurd>} />
                  <Route path="information" element={<RouteGaurd canAccess={CAN(Action.LIST, Resource.INFORMATION) || CAN(Action.CREATE, Resource.INFORMATION) || CAN(Action.GET, Resource.STATS)}><InformationViewer /></RouteGaurd>} />
                  <Route path="new-information" element={<RouteGaurd canAccess={CAN(Action.CREATE, Resource.INFORMATION)}><NewInformation /></RouteGaurd>} />

                  <Route path="archived-incidents" element={<RouteGaurd canAccess={CAN(Action.LIST, Resource.INCIDENT)}><ArchivedIncidents /></RouteGaurd>} />
                  <Route path="archived-incidents/:id" element={<RouteGaurd canAccess={CAN(Action.GET, Resource.INCIDENT)}><ArchivedIncident /></RouteGaurd>} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
          <Toaster />
        </AuthGaurd>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state)=>{
  return {
    role: state.role,
    myRights:state.myRights.myRights,
    myRightsLoading:state.myRights.loading
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    getRights:(userDetails)=>{
      return dispatch(getMyRights(userDetails))
    },
    updateRights:(userDetails)=>{
      return dispatch(updateMyRights(userDetails))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
