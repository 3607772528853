import { BASE_URL, GET, SERVICES } from "../constants";
import {statusParsing, jsonParsing} from "../utils";
import {getAccessToken} from "../../authActions/authActions";

export const get = async(next)=>{
    const token = await getAccessToken();
    let resource = `${BASE_URL}${SERVICES}`
    if(next){
        resource=`${resource}?next=${next}`
    }
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}