import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
const Map = ({ latlng,style, ...props }) => {
  const mapContainerStyle = {
    height: "400px",
    width: "600px",
  };

  const center = {
    lat: latlng ? latlng.lat : 0,
    lng: latlng ? latlng.lng :-180
  };

  const position = {
    lat: latlng ? latlng.lat : null, //29.7515,
    lng: latlng ? latlng.lng : null, //75.7139,
  };

  const onLoad = (marker) => {
  };
  return (
    <GoogleMap
      id="marker-example"
      mapContainerStyle={{mapContainerStyle, ...style}}
      zoom={20}
      center={center}
    >
      <Marker onLoad={onLoad} position={position}/>
    </GoogleMap>
  );
};
export default Map;
