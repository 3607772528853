import { BASE_URL, GET, EVENTS, POST, PUT, DELETE, PATCH } from "../constants";
import {statusParsing, jsonParsing} from "../utils";
import {getAccessToken} from "../../authActions/authActions";

export const fetchEvents = async(next)=>{
    const token = await getAccessToken();
    let resource = `${BASE_URL}${EVENTS}`
    if(next){
        resource=`${resource}?next=${next}`
    }
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type" : "application/json",
            "userauth": `bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const fetchEventsLocation = async(city)=>{
    const token = await getAccessToken();
    let resource = `${BASE_URL}${EVENTS}/location/${city}`

    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type" : "application/json",
            "userauth": `bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const addEvent = async(payload)=>{

    const token = await getAccessToken();
    const resource = `${BASE_URL}${EVENTS}`
    
    return fetch(resource, {
        method: POST,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}` 
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const updateEvent = async(payload, eventId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${EVENTS}/${eventId}`
    return fetch(resource, {
        method: PUT,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const publishEvent = async(payload, eventId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${EVENTS}/${eventId}`
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}


export const deleteEvent = async(eventId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${EVENTS}/${eventId}`
    return fetch(resource, {
        method: DELETE,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}