import {connect} from "react-redux"
import NewEvent from "./NewEvent"
import {addEvent} from "../../../redux/actions/events/newEvent.actions"
import {editEvent} from "../../../redux/actions/events/event.actions"
import { dataGroups } from "../../../redux/actions/userManagement/dataGroups.action";
import { getCities } from "../../../redux/actions/events/events.actions";


const mapStateToProps = (state)=>{
    return {
        submitting: state.newEvent.loading,
        isEditMode: state.event.isEditMode,
        details: state.event.details,
        dataGroupsList: state.dataGroups.dataGroups,
        myRights:state.myRights.myRights,
        cities:state.events.cities  
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        newEvent: (payload)=>{
            return dispatch(addEvent(payload))
        },
        edit: (payload, id)=>{
            return dispatch(editEvent(payload, id))
        },
        getDataGroups:() => {
            return dispatch(dataGroups());
        },
        getCities: (city)=>{
            return dispatch(getCities(city))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEvent);