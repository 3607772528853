import { BASE_URL } from "../config";
export {BASE_URL}


/** ***************************** HTTP VERBS ************************* */
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';

/******************************** HTTPS CODES */
export const HTTP_CODES = { UNAUTHORIZED : 401,BADREQUEST:400 }

/******************************** API Result Status *******************/
export const API_REQUEST_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure'
}

/************************* END POINTS *******************/

/************************* Reports ************************/
export const GENDER_INCIDENTS = "/gender-incidents";
export const TRANS_HISTORY_INCIDENTS = "/trans-history-incidents";
export const SEXUAL_ORIENTATION_INCIDENTS = "/orientation-incidents";
export const ETHNICITY_INCIDENTS = "/ethnicity-incidents";
export const AGE_INCIDENTS = "/age-incidents";
export const PERPETRATOR_INCIDENTS = "/perpetrator-incidents";
export const REPORTS_INCIDENTS = "/reports/incidents"
export const REPORTS_DEMOGRAPHICS = "/reports/demographics"

/************************* Events ************************/
export const EVENTS = "/events";

/************************* Information ************************/
export const INFORMATIONS = "/information";

/************************* Upload Images *****************/
export const UPLOAD_IMAGE = "/events/upload"

/************************ Support Services ****************/
export const SERVICES = "/services"

/***********************ROLES*******************************/
export const IAM_ROLESMASTER="/iam/roles";
export const IAM_DATAGROUP="/iam/datagroups";
export const IAM_FUNCTIONALROLES="/iam/functionalRoles";
export const RIGHTS="/iam/rights";
export const RIGHTS_ME="/iam/rights/me";
export const USERS="/iam/users"

/***********************Incidents*******************************/
export const INCIDENTS="/incidents";
export const INCIDENT_STATS = "/incidents/stats";

/************************** News *******************************/
export const NEWS = "/news";

