import toast from "react-hot-toast";

export const showError = (message,position='bottom-center')=>{
    toast.error(
        message,
        {
            position: position
        }
    )
}

export const showSuccess = (message,position='bottom-center')=>{
    toast.success(
        message,
        {
            position: position
        }
    )
}


