import moment from 'moment';
import { DateTime } from 'luxon';
import { DATE_FORMAT,TIMES_ZONES } from './constants';

export const toMoment = (secs)=>{
    return moment.unix(secs)
}

export const formatDate = (moment)=>{
    return moment.format("Do MMM YYYY")
}


export const formatSecondsToDate = (secs)=>{
    const isoDate = DateTime.fromMillis(secs*1000, {zone: TIMES_ZONES['gbr']}).toFormat(DATE_FORMAT)
    return isoDate
}