import {combineReducers} from "redux";
import incident from './incident.reducer';
import incidents from './incidents.reducer'
import archivedIncidents from "./archivedIncidents.reducer"
import archivedIncident from "./archivedIncident.reducer"

export default combineReducers({
    incident,
    incidents,
    archivedIncidents,
    archivedIncident
})