import { FETCH_FUNCTIONALROLES } from "../../actions/actionTypes";

const initialState = {
  functionalRoles: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FUNCTIONALROLES.REQUEST:
      return { ...state, loading: true };
    case FETCH_FUNCTIONALROLES.FAILURE:
      return { ...state, loading: false };
    case FETCH_FUNCTIONALROLES.SUCCESS:
      return { ...state, functionalRoles: [...action.functionalRoles], loading: false };
    default:
      return state;
  }
};

export default reducer;
