import {MAP_API_KEY} from "../../config"
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';
import { useEffect, useState } from "react";

const dropdownStyle = {
    control: (provided, state)=> {
        const {isDisabled} = state
        return (
            {...provided, backgroundColor: isDisabled ? "#999" : "#efefef", borderRadius: 5, height: 44, borderWidth: 1, borderColor: "#C5C1C8", marginBottom: 20,width:"600px"}
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#100045", fontFamily: "Moderat-Regular", fontSize: 13}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#837B8B", fontFamily: "Moderat-Regular", fontSize: 13}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    container: (provided, state)=> ({...provided, width: 500,}),
    input : (provided, state)=> ( { ...provided,
        backgroundColor: "transparent", color: "#100045", fontFamily: "Moderat-Regular", fontSize: 13
    })

}

const LocationAutocomplete = ({placeholder="", onChange,defaultInputValue})=>{
    return (
        <GooglePlacesAutocomplete
            
            autocompletionRequest={{
                componentRestrictions: {
                    country: ['uk','cz','al'], //resitricted to uk only. To be changed as per user's country
                }
            }} 
            onLoadFailed={(error)=>{
                
            }}
    
            selectProps={{
                defaultInputValue:defaultInputValue,
                styles : dropdownStyle,
                placeholder :  placeholder,
                blurInputOnSelect: true,
                onChange : onChange
            }}
        />
    );
}

export default LocationAutocomplete;

export {geocodeByPlaceId, getLatLng}