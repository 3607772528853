import {
  START_EDITING_SUPPORT_SERVICE,
  FETCH_SUPPORT_SERVICES,
  EDIT_SUPPORT_SERVICE,
  CANCEL_EDIT_SUPPORT_SERVICE
} from "../../actions/actionTypes";

const initialState = {
  isEditMode: false,
  details: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_EDITING_SUPPORT_SERVICE:
      return { ...state, isEditMode: true, details: action.service };
    case FETCH_SUPPORT_SERVICES.REQUEST:
      return { ...state, isEditMode: false, details: null };
    case CANCEL_EDIT_SUPPORT_SERVICE.REQUEST:
    case EDIT_SUPPORT_SERVICE.SUCCESS:
      return { ...state, isEditMode: false, details: null };
    default:
      return state;
  }
};

export default reducer;
