import React, {Component, useState} from "react";
import styles from "./newsItem.module.css"
import Container from "../../../components/sidebar/ContentContainer";
import { useParams } from "react-router-dom";

const withRouter = WrappedComponent => props => {
    const params = useParams();
   
    return (
      <WrappedComponent
        {...props}
        params={params}
      />
    );
};

class NewsItem extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount(){
        const {params: {id}} = this.props

        this.props.itemDetails(id)
    }

    render(){
        const {title, 
            published, 
            pubDate, 
            news_id, 
            link, 
            isoDate, 
            creator, 
            createdAt, 
            content, 
            categories,
            contentEncoded
        } = this.props.details
        return (
            <Container>
                <div>{title}</div>
                <div className={styles.snippet} dangerouslySetInnerHTML={{__html : contentEncoded}}></div>
            </Container>
        );
    }
}

export default withRouter(NewsItem)