import {ADD_EVENT, PUBLISH_EVENT } from "../actionTypes";
import { addEvent as addEventService, updateEvent as updateEventService, publishEvent as publishEventService} from "../../../api/events/events.service";

const addEventRequest = ()=>({type: ADD_EVENT.REQUEST})
const addEventFailure = (error)=>({type: ADD_EVENT.FAILURE, error})
const addEventSuccess = ()=>({type: ADD_EVENT.SUCCESS})

export const addEvent = (payload)=>{
    return (dispatch, getState)=>{
        dispatch(addEventRequest())
        return addEventService(payload)
        .then(response => {
            dispatch(addEventSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(addEventFailure(error))
            return Promise.resolve(false)
        })
    }
}


const publishEventRequest = ()=>({type: PUBLISH_EVENT.REQUEST})
const publishEventFailure = (error)=>({type: PUBLISH_EVENT.FAILURE, error})
const publishEventSuccess = ()=>({type: PUBLISH_EVENT.SUCCESS})

export const publishEvent = (payload, eventId)=>{
    return (dispatch, getState)=>{
        dispatch(publishEventRequest())
        return publishEventService(payload, eventId)
        .then(response => {
            dispatch(publishEventSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(publishEventFailure(error))
            return Promise.resolve(false)
        })
    }
}