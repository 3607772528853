import { HTTP_CODES, API_REQUEST_STATUS} from "./constants";

class UnauthorizedAccessError extends Error {
  constructor(message) {
    super(message);
    this.name = "Unauthorized Access Error";
    this.code = HTTP_CODES.UNAUTHORIZED;
  }
}

class BadRequestError extends Error {
  constructor(json) {
    super(json.message);
    this.name = "BadRequest";
    this.code =  json?.code || HTTP_CODES.BADREQUEST;
  }
}

class ParsingError extends Error {
  constructor(message) {
    super(message);
    this.name = "Parsing Error";
  }
}

class HTTPResponseError extends Error {
  constructor(response) {
    super("Something went wrong");
    this.name = "Server Error";
    this.response = response;
    this.code = response.status;
  }
}

class InteractionRequiredError extends Error {
  constructor(response){
    super("Interaction Required Error Occurred")
    this.name = "Interaction Required Error"
    this.data = response
  }
}

class ResultError extends Error {
  constructor(result){
    super("Request failed")
    this.name = "Result Error"
    this.message = result.description
  }
}

export const textParsing = response => response.text();

export const jsonParsing = async response => {
  try {
    const json = await response.json();
    return Promise.resolve(json);
  } catch (error) {
    return Promise.reject(new ParsingError(error.message));
  }
};

export const statusParsing = async response => {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  }else if(response.status === 403 || response.status === 400){

    return jsonParsing(response)
    .then((json)=>{
      return Promise.reject(new BadRequestError(json));
    })
  }

  return Promise.reject(new HTTPResponseError(response));
};

export const resultParsing = json => {
  if(json.result.status === API_REQUEST_STATUS.SUCCESS){
    return Promise.resolve(json)
  }else{
    return Promise.reject(new ResultError(json.result))
  }
}


