import { FETCH_ARCHIVED_INCIDENTS, FETCH_ARCHIVED_INCIDENT } from "../../actions/actionTypes";

const initialState = {
    data: [],
    next: null,
    loading: false
}

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_ARCHIVED_INCIDENTS.REQUEST:
                return {...state, loading:true}
        case FETCH_ARCHIVED_INCIDENTS.SUCCESS:{
            if(action.data.shouldLoadMore){
                return {...state, data: [...state.data, ...action.data.incidents], next : action.data.next, loading:false}
            }else{
                return {...state, data: action.data.incidents, next : action.data.next, loading:false}
            }
        }
            
        case FETCH_ARCHIVED_INCIDENTS.FAILURE:
            return {...state, loading:false}
        default:
            return state
    }
}

export default reducer;