import { useState } from "react"
import {confirmAlert} from "./Alerts"
import styles from "./confirmation.module.css"
import {ButtonSpinner} from "../loader/Loaders"
import {InputPrimary} from "../input/inputs"

export const BUTTON_TYPES = {
    Destructive: "destructive",
    Success: "success",
    Info: "information",
    Warning: "warning",
    General: "general",
    Cancel: "cancel"
}

const Spinner = ()=>{
    return (
        <div className={styles.spinner}><ButtonSpinner /></div>
    );
}

const Button = ({label, onClick, type, onFinish})=>{

    const [loading, setLoading] = useState(false)

    //while the action is being perfromed show the loader
    if(loading){
        return <Spinner />
    }

    let style = styles.button
    switch(type){
        case BUTTON_TYPES.Destructive:
            style = `${style} ${styles.delete}`
            break;
        case BUTTON_TYPES.Cancel:
            style = `${style} ${styles.cancel}`
            break;
        default:
            style = `${style} ${styles.default}`;
            break;
    }

    return (
        <div onClick={()=>{
            setLoading(true)
            onClick()
            .then(()=>{
                setLoading(false)
                onFinish()
            })
        }} className={style}>{label}</div>
    );
}


const Confirmation = ({title, message, buttons, onClose})=>{
    return (
        <div className={styles.container}>
            <label className={styles.title}>{title}</label>
            <label className={styles.message}>{message}</label>
            <div className={styles.buttonsContainer}>
                {
                    buttons.map((button)=> (<Button {...button} onFinish={onClose}/>))
                }
            </div>
        </div>
    );
}

export const showConfirmation = (title, message, buttons)=>{
     //The promise is returned so that a loader can be implemented in place of the button
     return new Promise((resolve, reject)=>{
        confirmAlert({
            afterClose: ()=>{
                /**
                 * This will cause the spinner on the delete icon to disapper
                 */
                resolve(true)
            },
            customUI: ({onClose})=>(
                <Confirmation 
                    onClose={onClose}
                    title={title}
                    message={message}
                    buttons={buttons}/>
            )
        })
    })
}

const ConfirmationWithInput = ({title,type,label,placeholderText,buttons,inputStyle, onClose,getInputValue})=>{
    let [value , setValue]= useState();
    const getvalue=()=>{
        getInputValue(value);
    }
    return (
        <div className={styles.container}>
            <label className={styles.title}>{title}</label>
            <div className={styles.inputmessage}>
            <InputPrimary
                type={type}
                label={label}
                value={value}
                placeholderText={placeholderText}
                inputStyle={inputStyle}
                onChange={(e) => {
                    setValue(value=e.target.value);
                    getvalue();
                }}
            />
            </div>
            <div className={styles.buttonsContainer}>
                {
                    buttons.map((button)=> (<Button {...button} onFinish={onClose}/>))
                }
            </div>
        </div>
    );
}

export const showConfirmationWithInput = (title,type,inputStyle,label,placeholderText,buttons,getInputValue)=>{
     //The promise is returned so that a loader can be implemented in place of the button
     return new Promise((resolve, reject)=>{
        confirmAlert({
            afterClose: ()=>{
                /**
                 * This will cause the spinner on the delete icon to disapper
                 */
                resolve(true)
            },
            customUI: ({onClose})=>(
                <ConfirmationWithInput 
                    onClose={onClose}
                    title={title}
                    buttons={buttons}
                    type={type}
                    label={label}
                    placeholderText={placeholderText}
                    inputStyle={inputStyle}
                    getInputValue={getInputValue}
                />
            )
        })
    })
}