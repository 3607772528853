import { ADD_USER } from "../actionTypes";
import { AddUser } from "../../../api/userManagement/userManagement.service";

const request = () => ({ type: ADD_USER.REQUEST });
const failure = (error) => ({ type: ADD_USER.FAILURE, error });
const success = (users) => ({ type: ADD_USER.SUCCESS, users });

export const createUser = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return AddUser(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});
      })
      .catch((error) => {
        dispatch(failure(error));
        return Promise.resolve({error:error,status:false});
      });
  };
};
