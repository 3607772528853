import { DELETE_INFORMATION, START_EDITING_INFORMATION, EDIT_INFORMATION, CANCEL_EDITING_INFORMATION, FETCH_INFORMATIONS } from "../../actions/actionTypes";

const initialState = {
    deleting: false,
    isEditMode: false,
    details: null  
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case DELETE_INFORMATION.REQUEST:
            return {...state, deleting: true}
        case DELETE_INFORMATION.SUCCESS:
        case DELETE_INFORMATION.FAILURE:
            return {...state, deleting: false} 
        case START_EDITING_INFORMATION:
            return {...state, isEditMode: true, details: action.information}
        case CANCEL_EDITING_INFORMATION:
        case FETCH_INFORMATIONS.REQUEST:
            return {...state, isEditMode: false, details: null}
        case EDIT_INFORMATION.SUCCESS:
            return {...state, isEditMode: false, details: null}
        default:
            return state
    }
}

export default reducer;