import {BASE_URL, GET, REPORTS_DEMOGRAPHICS} from "../constants"
import {statusParsing, jsonParsing} from "../utils"
import {getAccessToken} from "../../authActions/authActions";

export const get = async(filter, type)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${REPORTS_DEMOGRAPHICS}?type=${type}&country=${filter}`
    return fetch(resource, {
        method : GET,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}