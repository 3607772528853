const communities = [
    {label: "All LGBTQ communities", value: "all-lgbt-comms"},
    {label: "Asexual people", value: "asexual"},
    {label: "Bisexual people", value: "bisexual"},
    {label: "Gay people", value: "gay"},
    {label: "Lesbian people", value: "lesbian"},
    {label: "People of faith", value: "people-faith"},
    {label: "People over 50", value: "people-50"},
    {label: "People of colour", value: "people-colour"},
    {label: "People with disabilities", value: "people-disable"},
    {label: "Queer people", value: "queer"},
    {label: "Transgender people", value: "transgender"},
    {label: "Young people", value: "young"},
]

export const get = ()=>{
    return Promise.resolve(communities)
}