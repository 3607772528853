import {FETCH_ARCHIVED_INCIDENT } from "../../actions/actionTypes";

const initialState = {
    data: null,
    loading: false
}

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_ARCHIVED_INCIDENT.REQUEST:
            return {...state, data: null, loading: true}
        case FETCH_ARCHIVED_INCIDENT.SUCCESS:
            return {...state, data: action.data, loading: false}
        case FETCH_ARCHIVED_INCIDENT.FAILURE:
            return {...state, loading: false}
        default:
            return state
    }
}

export default reducer;