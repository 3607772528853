import React, {Component} from "react";
import {VictoryChart, VictoryBar, VictoryTheme, VictoryLabel, VictoryGroup, VictoryAxis} from "victory"

export default class ColumnChart extends Component {

    constructor(props){
        super(props)
        this.chartRef = React.createRef();
    }

    componentDidMount(){
    }

    render(){
        const {data} = this.props
        return (
            <>
                <div ref={this.chartRef}>
                    <VictoryChart
                        theme={VictoryTheme.material}
                        domainPadding={10}
                        >
                        <VictoryAxis dependentAxis/>
                        <VictoryBar
                            horizontal
                            alignment="start"
                            barRatio={0.6}
                            data={data}
                            style={
                                { 
                                    labels: {
                                        fill: "#28008C" 
                                    }, 
                                    data : {
                                        fill: "#FFE6E6",
                                        stroke: "#28008C",
                                        strokeWidth: 1
                                    } 
                                }
                            }
                            labelComponent={<VictoryLabel dy={-10}/>}
                            labels={({ datum, scale }) => {
                                return `${datum.x} (${datum.y})`
                            }}
                        />
                    </VictoryChart>
                </div>
            </>
        );
    }
}