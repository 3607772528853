import {SUPPORT_SERVICE_TYPE} from "../actionTypes"
import {get} from "../../../api/supportServices/serviceTypes.service"

const request = ()=>({type: SUPPORT_SERVICE_TYPE.REQUEST})
const failure = (error)=>({type: SUPPORT_SERVICE_TYPE.FAILURE, error})
const success = (serviceTypes)=>({type: SUPPORT_SERVICE_TYPE.SUCCESS, serviceTypes})

export const supportServiceTypes = ()=>{
    return (dispatch, getState)=>{
        dispatch(request())
        return get()
        .then(response=>{
            dispatch(success(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(failure(error))
            return Promise.resolve(false)
        })
    }
}