import { DELETE_EVENT, START_EDITING_EVENT, EDIT_EVENT, CANCEL_EDITING_EVENT, FETCH_EVENTS } from "../../actions/actionTypes";

const initialState = {
    deleting: false,
    isEditMode: false,
    details: null
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case DELETE_EVENT.REQUEST:
            return {...state, deleting: true}
        case DELETE_EVENT.SUCCESS:
        case DELETE_EVENT.FAILURE:
            return {...state, deleting: false}
        case START_EDITING_EVENT:
            return {...state, isEditMode: true, details: action.event}
        case CANCEL_EDITING_EVENT:
        case FETCH_EVENTS.REQUEST:
            return {...state, isEditMode: false, details: null}
        case EDIT_EVENT.SUCCESS:
            return {...state, isEditMode: false, details: null}
        default:
            return state
    }
}

export default reducer;