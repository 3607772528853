import {FETCH_SUPPORT_SERVICES} from "../actionTypes"
import {get} from "../../../api/supportServices/supportServices.service"



const servicesRequest = ()=>({type: FETCH_SUPPORT_SERVICES.REQUEST})
const servicesFailure = (error)=>({type: FETCH_SUPPORT_SERVICES.FAILURE, error})
const servicesSuccess = (obj)=>({type: FETCH_SUPPORT_SERVICES.SUCCESS, obj})

export const getServices = (isLoadMore,nextPage)=>{
    return (dispatch, getState)=>{
        dispatch(servicesRequest())
        return get(nextPage)
        .then(response => {
            let obj={
                'data':response,
                'isLoadMore':isLoadMore
            }
            dispatch(servicesSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(servicesFailure(error))
            return Promise.resolve(false)
        })
    }
}