import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./sidebar.module.css";
import {connect} from "react-redux";
import {selectMenu} from "../../redux/actions/sidebar/sidebar.actions"
import {SIDE_BAR_MENU_ITEMS} from "../../utils/constants";
import IMAGES from "../../assets/images/images";
import {ADMIN} from "../../utils/constants"
import {
    Outlet
} from "react-router-dom";
import { useEffect } from "react";
import {useLocation } from "react-router-dom";
import CAN from "../../casl/can";
import {Action,Resource} from "../../casl/constants";




const Sidebar = (props)=>{
    let {selected, selectMenu, role} = props
    let items
    const location = useLocation();
    let _Items = [
        {path: "/dashboard",canAccess:CAN(Action.GET, Resource.REPORTS), value:  SIDE_BAR_MENU_ITEMS.REPORTS, label: "Dashboards", icon: {selected: IMAGES.dashboard_selected, regular:  IMAGES.dashboard}},
        // {path: "/events", canAccess:CAN(Action.LIST, Resource.EVENT) || CAN(Action.GET, Resource.STATS), value: SIDE_BAR_MENU_ITEMS.EVENTS, label: "Events", icon: {selected:  IMAGES.events_selected, regular: IMAGES.events}},
        // {path: "/information", canAccess:CAN(Action.LIST, Resource.INFORMATION) || CAN(Action.GET, Resource.STATS), value: SIDE_BAR_MENU_ITEMS.INFORMATION, label: "Information Articles", icon: {selected:  IMAGES.articles_selected, regular: IMAGES.articles}},
        {path: "/events", canAccess:CAN(Action.LIST, Resource.EVENT) , value: SIDE_BAR_MENU_ITEMS.EVENTS, label: "Events", icon: {selected:  IMAGES.events_selected, regular: IMAGES.events}},
         {path: "/information", canAccess:CAN(Action.LIST, Resource.INFORMATION) , value: SIDE_BAR_MENU_ITEMS.INFORMATION, label: "Information Articles", icon: {selected:  IMAGES.articles_selected, regular: IMAGES.articles}},
        {path: "/services", canAccess:CAN(Action.LIST, Resource.SERVICES), value: SIDE_BAR_MENU_ITEMS.SERVICES, label: "Support Services", icon: {selected: IMAGES.services_seleted, regular: IMAGES.services}},
        {path: "/news", canAccess:CAN(Action.LIST, Resource.FEED), value: SIDE_BAR_MENU_ITEMS.NEWS, label: "News", icon: {selected: IMAGES.news_selected, regular: IMAGES.news}},
        {path: "/users", canAccess:CAN(Action.LIST, Resource.USERS), value: SIDE_BAR_MENU_ITEMS.USER_MANAGEMENT, label: "User Management", icon: {selected: IMAGES.roles_selected, regular: IMAGES.roles}},
        {path: "/incidents", canAccess:CAN(Action.LIST, Resource.INCIDENT), value: SIDE_BAR_MENU_ITEMS.INCIDENT, label: "Incidents", icon: {selected: IMAGES.incidents_selected, regular: IMAGES.incidents}},
        ///{path: "/configuration", canAccess:CAN(Action.CREATE, Resource.USERS), value: SIDE_BAR_MENU_ITEMS.CONFIGURATION, label: "Configuration", icon: {selected: IMAGES.configuration_selected, regular: IMAGES.configuration}},
        {path: "/archived-incidents", canAccess:CAN(Action.LIST, Resource.INCIDENT), value: SIDE_BAR_MENU_ITEMS.ARCHIVED_INCIDENTS, label: "Archived Incidents", icon: {selected: IMAGES.archives_selected, regular: IMAGES.archives}},
    ];
    const [Items,setItems]=useState(_Items);
    switch(role){
        case ADMIN.Content:
            items = [Items[1], Items[2]];
            props.selectMenu(SIDE_BAR_MENU_ITEMS.EVENTS) //probably this should go in component did mount
            break;
        case ADMIN.Reports:
            items = [Items[0]];
            break;
        default:
            items = Items;
    }

 
    
    useEffect(()=>{
       let _path = location.pathname;
       let index=Items.findIndex(x => x.path ===_path);
       if(index !== -1){
        let _pathValue=Items[index].value;
        props.selectMenu(_pathValue);
       }
    },[Items,location.pathname])

    return (
        <div className={styles.container}>
            <nav className={styles.sidebarWrapper}>
                <div className={styles.sidebarMenu}>
                    <ul className={styles.sidebarList}>
                        {
                            items.map((item, index)=> {
                                let style = `${styles.menuItem}`
                                let icon = item.icon.regular
                                if(selected === item.value){
                                    style = `${style} ${styles.activeItem}`
                                    icon = item.icon.selected
                                }

                                return (
                                    item.canAccess?
                                    (<Link
                                        key={`${item.valuke}-${index}`}
                                        className={styles.itemLink} 
                                        to={item.path} 
                                        onClick={()=>{
                                            selectMenu(item.value)
                                    }}>
                                        <div className={style}>
                                            <div className={styles.menuItemContainer}>
                                                <img className={styles.menuIcon} src={icon}/>
                                                <span className={styles.menuTitle}>{item.label}</span>
                                            </div>
                                        </div>
                                    </Link>):<></>
                                );

                            })
                        }
                    </ul>
                </div>
            </nav>
            <div className={styles.content}>
                <Outlet />
            </div>
            
        </div>
    );
}

const mapStateToProps = (state)=>{
    return {
        selected: state.selectedMenu,
        role: state.role
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        selectMenu: (item)=>{
            return dispatch(selectMenu(item))
        }
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);