import { FETCH_MYRIGHTS,UPDATE_MYRIGHTS } from "../actionTypes";
import { fetchMyRights } from "../../../api/userManagement/userManagement.service";

const request = () => ({ type: FETCH_MYRIGHTS.REQUEST });
const failure = (error) => ({ type: FETCH_MYRIGHTS.FAILURE, error });
const success = (myRights) => ({ type: FETCH_MYRIGHTS.SUCCESS, myRights });

export const getMyRights = () => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchMyRights()
      .then((res) => {
        dispatch(success(res))
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(failure(error))
        return Promise.reject('');
      });
  };
};


export const updateMyRights=(myRights)=>{
    return  {
      type: UPDATE_MYRIGHTS ,
      myRights
  }
}
