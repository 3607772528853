import React, {useState} from "react"
import { ButtonPrimary, LinkButton } from "../../components/button/buttons";
import { useNavigate } from "react-router-dom";
import { logOut, enrollSecondFactor, complete2FAEnrollment, enableRecaptcha } from "../../authActions/authActions";
import DropDown from "../../components/dropDown/CountryCode"
import styles from "./mfa.module.css"
import {showSuccess, showError} from "../../utils/toasts"
import {getErrorMessage} from "../../utils/errors"


const countryCodes = [
    { label: "UK-44", value: "+44" },
    { label: "CZE-420", value: "+420" },
    { label: "ALB-355", value: "+355" }
];

const TwoFactorAuth = ({ onSendOTP})=>{
    const [phone, setPhone] = useState("")
    const [code, setCode] = useState("")
    enableRecaptcha()
    return (
        <div className={styles.mfaContainer}>
            <h2 className={styles.title}>Enable Two Factor Authentication</h2>
            {/* <label>You need to enable Two-Factor authentication before you can access the Admin Portal</label> */}
            <span className={styles.description}>For security purposes, we have implemented two factor authentication to login to the Zoteria admin portal.</span>

            <span className={styles.description}>Please provide your phone number below, and we will send a verification code to your phone via SMS.</span>
            <div className={styles.phoneContainer}>
                <CountryCode 
                    codes={countryCodes}
                    onCodeSelect={(value)=>{
                        setCode(value)
                    }}/>
                <input 
                    className={styles.phoneInput}
                    type={"number"} 
                    placeholder="Enter mobile number"
                    onChange={(event)=>{ 
                        setPhone(event.target.value)
                    }}/>
            </div>
            <span className={styles.message}>Do not prefix a 0 before the mobile number.</span>

            <ButtonPrimary 
                    id={"captcha-container"}
                    isDisabled={(!code || phone.toString().length < 10 || phone.toString().length > 15 )}
                    onClick={()=>{
                        onSendOTP(`${code}${phone}`)
                    }}>Send Code</ButtonPrimary>
        </div>
    );
}

const CountryCode = ({codes, onCodeSelect, hasError=false})=>{
    return (
        <DropDown
            options={codes}
            onChange={({ label, value }) => {
                onCodeSelect(value)
            }}
            hasError={hasError}
      />
    );
}

const OTPVerification = ({onPhoneReset, phone, onVerify})=>{
    const [otp, setOTP] = useState("")
    const [deviceName, setDeviceName] = useState("My Phone")
    return (
        <div className={styles.mfaContainer}>
            <h2>Verification Code</h2>
            {/* <label>You need to enable Two-Factor authentication before you can access the Admin Portal</label> */}
            <span className={styles.description2}>{`Please enter the code you have received on ${phone}.`}</span>

            <span className={styles.description2}>You will receive this via SMS.</span>
            <div className={styles.otpContainer}>
                <input
                    className={styles.otpInput}
                    type={"number"} 
                    placeholder="Enter verification code"
                    onChange={(event)=>{
                        setOTP(event.target.value)
                    }}/>
                <input 
                    className={styles.otpInput}
                    placeholder="Name your device eg: My iPhone"
                    onChange={(event)=>{
                        setDeviceName(event.target.value)
                    }}/>
            </div>
            <ButtonPrimary
                    onClick={ ()=>{
                        onVerify(otp, deviceName)
                        
                    }}>Verify</ButtonPrimary>
            <LinkButton
                title={"Incorrect phone number? Click here to re-enter."}
                onClick={onPhoneReset} />
        </div>
    );
}


const MultifactorEnabler = (props)=> {
    const [detailsCaptured, setDetailsCaptured] = useState(false) 
    const [phone, setPhone] = useState("")
    const [verificationId, setVerificationId] = useState("")

    const onSendOTP = async (phone)=>{
        try {
            setPhone(phone)
            const verifyId = await enrollSecondFactor(phone)
            setVerificationId(verifyId)
            setDetailsCaptured(true)
        } catch (error) {
            const message = getErrorMessage(error.code)
            showError(message)
        }
    }

    const onVerifyOtp = async (otp, deviceName)=>{
        try {
            const result = await complete2FAEnrollment(verificationId, otp, deviceName)
            logOut()
            .then((res) => {
                navigate("/login");
                showSuccess("OTP Verified. Please login to continue!")
                setTimeout(()=>{
                    window.location.reload();
                }, 1000)
            })
            .catch((error) => {
            });
        } catch (error) {
            if(error.code === "auth/missing-verification-id"){
                showError("Refresh the page and try again.")
            }
        }
    }

    const signInWithDifferentUser = () => {
        logOut()
        .then((res) => {
            navigate("/login");
            window.location.reload();
        })
        .catch((error) => {
        });
    }
    
    const navigate = useNavigate();
        return (
            <div className={styles.root}>
                <div className={styles.container}>
                    {
                        detailsCaptured ?
                        <OTPVerification 
                            phone={phone}
                            onPhoneReset={()=>{
                                setDetailsCaptured(false) 
                            }}
                            onVerify={onVerifyOtp}/>
                        :
                        <TwoFactorAuth 
                            onSendOTP={onSendOTP}/>
                    }
                    <LinkButton
                        title={"Click here to sign in with a different user"}
                        onClick={signInWithDifferentUser}
                    />
                </div>
            </div>
        );
}

export default MultifactorEnabler;