import {START_EDITING_USER,EDIT_USER} from "../actionTypes"
import {editRights} from "../../../api/userManagement/userManagement.service"

export const startEditing = (user)=>{
    return {type: START_EDITING_USER, user}
}

const editRequest = ()=>({type: EDIT_USER.REQUEST})
const editFailure = (error)=>({type: EDIT_USER.FAILURE, error})
const editSuccess = ()=>({type: EDIT_USER.SUCCESS})

export const editUser = (payload, uid)=>{
    return (dispatch, getState)=>{
        dispatch(editRequest())
        return editRights(payload, uid)
        .then(response => {
            dispatch(editSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(editFailure(error))
            return Promise.resolve(false)
        })
    }
}
