import React, {Component, createContext, useContext} from "react";
import styles from "./tabs.module.css";

const DataContext = createContext({});

export const TabList = ({children})=>{
    return (
        <div className={styles.tablist}>
            {children}
        </div>
    );
}

export const TabPanels = ({children})=>{
    return (
        <div className={styles.tabpanels}>
            {children}
        </div>
    );
}

export const TabPanel = ({children, id})=>{
    const [activeTabID] = useContext(DataContext);
    const selected = activeTabID === id
    return (
        selected ?
        <div className={styles.tabpanel}>
            {children}
        </div>
        :
        null
    ); 
}

const withSelectedState = (Component, selected, lable)=> {
    return (
        <Component selected={selected} lable={lable}/>
    )
}

export const Tab = ({lable, id, component=null,onSelect = null})=>{

    const [activeTabID, setActiveTab] = useContext(DataContext);
    const selected = activeTabID === id
    let style = `${styles.tabContainer}`
    if(selected){
        style = `${style} ${styles.tabSelected}`
    }
    return (
        <div className={style} onClick={()=>{
            setActiveTab(id)
            onSelect?.(id)
        }}>
            {
                component
                ?
                withSelectedState(component, selected, lable)
                :
                <h2>{lable}</h2>
            }
            
            
        </div>
    );
}

export const TabContent = ({id, children, label})=>{
    const [activeTabID] = useContext(DataContext)
    const selected = activeTabID === id
    return (
        <div className={styles.tabContentContainer}>
            {
                selected ? <span>{label}</span> : null
            }
        </div>
    );
}


export default class Tabs extends Component {
    constructor(props){
        super(props)
        this.state = {
            activeTab: props.initialTab ?? null
        }
        this.setActiveTab = this.setActiveTab.bind(this)
    }

    setActiveTab(id){
        this.setState({activeTab: id})
    }

    render(){
        const {children} = this.props
        const {activeTab} = this.state
        return (
            <DataContext.Provider value={[activeTab, this.setActiveTab]}>
                <div className={styles.container}>
                    {children}
                </div>
            </DataContext.Provider>
        );
    }
}