export const countries = [
    {label: "Global", value : "global"},
    {label: "United Kingdom", value : "uk"},
    {label: "Albania", value : "albania"},
    {label: "Czech Republic", value : "czech-republic"}
]

export const gender = [
    {label: "Female", value : "female"},
    {label: "Male", value : "male"},
    {label: "Non Binary", value : "non-binary"},
    {label: "Other", value : "other"},
    {label: "Prefer Not To Disclose", value : "prefer-not-to-disclose"},
]

export const transHistory = [
    {label: "Yes", value : "yes"},
    {label: "No", value : "no"},
    {label: "Prefer Not To Disclose", value : "prefer-not-to-disclose"},
]

export const ethnicity = [
    {label: "Asian / Asian British: South Asian", value : "south-asian"},
    {label: "Asian / Asian British: Pakistani", value : "pakistani"},
    {label: "Asian / Asian British: Bangladeshi", value : "bangladeshi"},
    {label: "Arab", value : "arab"},
    {label: "Any other Asian background", value : "other-asian"},
    {label: "Black / Black British: African", value : "african"},
    {label: "Black / Black British: Caribbean", value : "caribbean"},
    {label: "Any other Black background", value : "other-black"},
    {label: "Latinx", value : "latinx"},
    {label: "Chinese / Chinese British", value : "chinese"},
    {label: "Mixed – White/Black: African", value : "mixed-african"},
    {label: "Mixed – White/Black: Caribbean", value : "mixed-caribbean"},
    {label: "Any other mixed background", value : "other-mixed"},
    {label: "White", value : "white"},
    {label: "Other", value : "other"},
    {label: "Prefer not to say", value : "prefer-not-to-disclose"},

]

export const orientation = [
    {label: "Lesbian", value : "lesbian"},
    {label: "Gay", value : "gay"},
    {label: "Bisexual", value : "bisexual"},
    {label: "Ace (including asexual, demisexual, greysexual)", value : "ace"},
    {label: "Heterosexual/Straight", value : "heterosexual"},
    {label: "Queer", value : "queer"},
    {label: "Prefer not to say", value : "prefer-not-to-disclose"},
    {label: "Other", value : "other"}
]

export const age = [
    {label: "Under 18", value : "under-18"},
    {label: "18-24", value : "18-24"},
    {label: "25-34", value : "25-34"},
    {label: "35-44", value : "35-44"},
    {label: "45-54", value : "45-54"},
    {label: "55-64", value : "55-64"},
    {label: "65+", value : "65-plus"},
    {label: "Prefer not to say", value : "prefer-not-to-disclose"}
]