import { FETCH_ROLESMASTER } from "../actionTypes";
import { fetchRolesMaster } from "../../../api/userManagement/userManagement.service";

const request = () => ({ type: FETCH_ROLESMASTER.REQUEST });
const failure = (error) => ({ type: FETCH_ROLESMASTER.FAILURE, error });
const success = (rolesMaster) => ({ type: FETCH_ROLESMASTER.SUCCESS, rolesMaster});

export const rolesMaster = () => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchRolesMaster()
      .then((res) => {
        return dispatch(success(res.roles));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };
};
