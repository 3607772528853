import { DELETE_USER } from "../actionTypes";
import { deleteUser as deleteService } from "../../../api/userManagement/userManagement.service";
 
const deleteRequest = ()=>({type: DELETE_USER.REQUEST});
const deleteFailure = (error)=>({type: DELETE_USER.FAILURE, error})
const deleteSuccess = ()=>({type: DELETE_USER.SUCCESS})


export const deleteUser = (uid)=>{
    return (dispatch, getState)=>{
        dispatch(deleteRequest())
        return deleteService(uid)
        .then(response => {
            dispatch(deleteSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(deleteFailure(error))
            return Promise.resolve(false)
        })
    }
}