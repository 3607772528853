const style={
    color: "#100045",
    textAlign: "center",
}

const Header = ({title})=>{
    return (
        <h3 style={style}>{title}</h3>
    );
}

export default Header;