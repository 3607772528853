import NewsItem from "./NewsItem";
import { connect } from "react-redux";
import {newsItemDetails} from "../../../redux/actions/news/news.actions"

const mapStateToProps = (state)=>{
    return {
        details: state.news.selectedFeedItem
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        itemDetails : (id)=>{
            return dispatch(newsItemDetails(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsItem)