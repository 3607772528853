import styles from "./404.module.css"

const NotFound = ()=>{
    return (
        <div>
            Seems you are lost
        </div>
    );
}

export default NotFound;