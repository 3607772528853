import { FETCH_ROLESMASTER } from "../../actions/actionTypes";

const initialState = {
  rolesMaster: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLESMASTER.REQUEST:
      return { ...state, loading: true };
    case FETCH_ROLESMASTER.FAILURE:
      return { ...state, loading: false };
    case FETCH_ROLESMASTER.SUCCESS:
      return { ...state, rolesMaster: [...action.rolesMaster], loading: false };
    default:
      return state;
  }
};

export default reducer;
