import {combineReducers} from "redux";
import serviceTypes from "./serviceTypes.reducer"
import communities from "./communities.reducer"
import services from "./supportServices.reducer"
import service from "./supportService.reducer"

export default combineReducers({
    serviceTypes,
    communities,
    services,
    service
})