import { BASE_URL, UPLOAD_IMAGE, POST } from "../constants";
import {statusParsing, jsonParsing} from "../utils";
import {getAccessToken} from "../../authActions/authActions";

export const uploadImage = async({file, name})=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${UPLOAD_IMAGE}`
    var formdata = new FormData();
    formdata.append("file", file, name);

    var requestOptions = {
        method: POST,
        body: formdata,
        redirect: 'follow',
        headers:{
            "userauth":`bearer ${token}`
        }
    };

    return fetch(resource, requestOptions)
    .then(statusParsing)
    .then(jsonParsing)
}