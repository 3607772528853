import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SectionLoader } from "../components/loader/Loaders";
import Container from "../components/sidebar/ContentContainer";
import { ROLES } from "../utils/constants";
import { app } from '../configuration/gcp-identity-platform-config';
import { getAuth } from "firebase/auth";

const AuthGaurd = ({ children,...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let [urlState, setURLState] = useState("");

  useEffect(() => {
    if(location.pathname !== '/login'|| location.pathname !== '/verifyemail' || location.pathname !== "/enable-mfa"){
        setURLState(urlState=location.pathname );
    }
    
    const hasRights = props.userDetails? true : false;
    const emailVerified = props.userDetails? getAuth(app).currentUser.emailVerified : false;
    const isValidMFAUser = getAuth(app).currentUser?.providerData[0]?.providerId === "password" ? true : false
    const hasMFAEnabled = getAuth(app).currentUser?.reloadUserInfo?.mfaInfo ? true : false
     if((hasRights && emailVerified)){
        if (urlState === "/login" ||  urlState ==="/") {
          let _defaultPath=getDefaultlandingPgae(props.userDetails.functionalRoles);
          navigate(_defaultPath);
        } else{
          navigate(urlState);
        }
      }
      else if(hasRights && emailVerified ===false){ 
        navigate("/verifyemail");
      }
      else if (isValidMFAUser && hasMFAEnabled === false){
        navigate("/enable-mfa");
      }
      else{
        navigate("/login", { state: { path: urlState } });
      };
  }, [location.pathname,props.userDetails,props.isAppLoading]);

  const getDefaultlandingPgae = (roles) => {  // to set default landing page if urlState is empty 
    if(roles.indexOf(ROLES.GLOBAL_REPORT_ANALYST) !== -1){
      return "/dashboard";
    }else if(roles.indexOf(ROLES.COUNTRY_REPORT_ANALYST) !== -1){
      return "/dashboard";
    }else if(roles.indexOf(ROLES.GLOBAL_CONTENT_MANAGER) !== -1){
      return "/events";
    }else if(roles.indexOf(ROLES.LOCAL_CONTENT_MANAGER) !== -1){
      return "/events";
    }else if(roles.indexOf(ROLES.ADMIN) !== -1){
      return "/users";
    }else if(roles.indexOf(ROLES.CSO_INCIDENT_MANAGER) !== -1){
      return "/incidents";
    }
    return "/dashboard";
  };

  return(
    props.isAppLoading?
    (<Container>
      <SectionLoader/>
    </Container>
    ):children
  ) 
};
//https://javascript.plainenglish.io/route-guards-in-react-5441b7d31334
export default AuthGaurd;
