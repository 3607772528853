import {connect} from "react-redux";
import {setRole} from "../../redux/actions/userManagement/roles.actions"
import Login from "./Login";

const mapStateToProps = null;

const mapDispatchToProps = (dispatch)=>{
    return {
        setRole: (role)=>{
            return dispatch(setRole(role))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);


