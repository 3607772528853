import React from "react";
import Container from "../components/sidebar/ContentContainer";

const NoAccess=()=>{
 return(
   <Container>
     <h1> No Access.</h1>
   </Container>
 )
}
const RouteGaurd = ({ children, canAccess, ...props }) => {
  if (canAccess) {
    return children;
  } else {
    return <NoAccess />;
  }
};
export default RouteGaurd;
