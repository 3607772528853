import {UPLOAD_IMAGE} from "../actionTypes"
import { uploadImage as imageService } from "../../../api/imageUploader/images.service";

const uploadImageRequest = ()=>({type: UPLOAD_IMAGE.REQUEST});
const uploadImageFailure = (error)=>({type: UPLOAD_IMAGE.FAILURE, error})
const uploadImageSuccess = ()=>({type: UPLOAD_IMAGE.SUCCESS})


export const uploadImage = (payload)=>{
    return (dispatch, getState)=>{
        dispatch(uploadImageRequest())
        return imageService(payload)
        .then(response => {
            dispatch(uploadImageSuccess())
            return response.url
        })
        .catch(error => {
            dispatch(uploadImageFailure(error))
            throw error
        })
    }
}