import {DEMOGRAPHIC_REPORT} from "../actionTypes"
import {get} from "../../../api/reports/demographicGraphs.service"

export const DEMOGRAPHIC_GRAPH_TYPE = {
    GENDER: "gender",
    TRANS_HISTORY: "transHistory",
    ORIENTATION: "sexualOrientation",
    ETHNICITY: "ethnicity",
    AGE: "age"
}


const request = (graphType)=>({type: DEMOGRAPHIC_REPORT.REQUEST, graphType})
const failure = (error, graphType)=>({type: DEMOGRAPHIC_REPORT.FAILURE, error, graphType});
const success = (incidents, graphType,totalCalls,currentIndex)=>({type: DEMOGRAPHIC_REPORT.SUCCESS, incidents, graphType,totalCalls,currentIndex})


export const getDemographicReport = (graphType, filters=null,totalCalls,currentIndex)=>{
    return (dispatch, getState)=>{
        dispatch(request(graphType))
        let _filter=filters.join("|");
        return get(_filter, graphType)
        .then(result => {
            dispatch(success(result, graphType,totalCalls,currentIndex))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(failure(error, graphType))
            return Promise.resolve(false)
        })
    }
}