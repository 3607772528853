import React, { useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import styles from "./tooltip.module.css";
import IMAGES from "../../assets/images/images"


export const Image = ({image})=>{
  return (
      <img className={styles.iconStyle} src={image}/>
  );
}

const Tooltip = (props) => {
  let [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={["right","left"]}
        padding={10}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={"#E5E5E5"}
            arrowSize={10}
            arrowStyle={{ opacity: 0.7 }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div
              className={styles.bodyContent}
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            >
             {props.info}
            </div>
          </ArrowContainer>
        )}
      >
        <div
          className={`${styles.IconConatiner} ${props.style}`} 
          onMouseLeave={() => setIsPopoverOpen((isPopoverOpen = false))}
          onMouseOver={() => setIsPopoverOpen((isPopoverOpen = true))}
        >
          <Image image={IMAGES.info}/>
        </div>
      </Popover>
    </>
  );
};
export default Tooltip;
