import styles from "./section.module.css"

const Separator = ()=>{
    return <div className={styles.separator}/>
}

const Section = ({children, title})=>{
    return (
        <div className={styles.section}>
            <h2 className={styles.sectionTitle}>{title}</h2>
            {
                children
            }
            <Separator />
        </div>
    );
}

export const FieldWrapper = ({ children }) => {
    return <div className={styles.fieldContainer}>{children}</div>;
};

export default Section;