import styles from "./header.module.css";
import { logOut } from "../../authActions/authActions";
import { useNavigate } from "react-router-dom";
import {connect} from "react-redux";
import { useState, useEffect } from "react";
import { IDLE_TIMEOUT } from "../../utils/constants";
import { Popover, ArrowContainer} from 'react-tiny-popover'
import { getAuth } from "firebase/auth";
import { app } from '../../configuration/gcp-identity-platform-config';

const initials = (name="")=> {
  name = name.replace(/\s{2,}/g,' ').trim() 
  return name.split(" ").reduce((agg, part)=> `${agg}${part[0]}`, "")
}

const UserInitials = ({name, email})=>{
  let [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'right']} // preferred positions by priority
      content={(
        <div
        className={styles.popup}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        <span>{name}</span>
        <span>{email}</span>
      </div>
      )}
    >
      <div
        onMouseLeave={() => setIsPopoverOpen((isPopoverOpen = false))}
        onMouseOver={() => setIsPopoverOpen((isPopoverOpen = true))} 
          className={styles.avatar}>{initials(name).toUpperCase()}
      </div> 
    </Popover>
  );
}

const Header = ({ title = "admin portal" , ...props}) => {
  const navigate = useNavigate();
  var isEmailVerified = false

  //Check if user email is verified or not
  if(getAuth(app).currentUser != null){
    isEmailVerified = getAuth(app).currentUser.emailVerified
  }

  const [timeOutWarning, setTimeOutWarning] = useState(false);
  const appLogout = () => {
    logOut()
      .then((res) => {
        navigate("/login");
        window.location.reload();
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    if(props.profile){
      var _idleSecondsCounter = 0;
      document.onclick = function() {
          setTimeOutWarning(false);
          resetCount()
      };
      document.onmousemove = function() {
         setTimeOutWarning(false);
         resetCount()
      };
      document.onkeypress = function() {
         setTimeOutWarning(false);
         resetCount()
      };
  
      function resetCount(){
        _idleSecondsCounter = 0;
      }
  
      window.setInterval(CheckIdleTime, 1000);
  
      function CheckIdleTime() {
          _idleSecondsCounter++;
          if(_idleSecondsCounter>=IDLE_TIMEOUT/2){
            setTimeOutWarning(true);
            if (_idleSecondsCounter >= IDLE_TIMEOUT) {
                setTimeOutWarning(false);
                appLogout();
            }
          }
      }
    }
  },[]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src={require("../../assets/images/logo.png")}
          />
          <h2 className={styles.title}>{title}</h2>
        </div>
        {/* If user email not verified, don't show display name & logout button. */}
        {props.profile && isEmailVerified  ? (
          <div className={styles.rightNavMenu}>
            {/* <div className={styles.rightNavMenuItem}></div>
            <div className={styles.rightNavMenuItem}></div>*/}
            <UserInitials name={props.profile.displayName} email={props.profile.email}/>
            <div
              className={styles.logOutBtn}
              onClick={()=>appLogout()}
            ></div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {timeOutWarning ? (
      <div className={styles.timeOutPanel}>
        Your session will be logged out as you were inactive for some time. If you still want to continue, then please perform some actions on this window
      </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile : state.myRights.myRights
  };
};
const mapDispatchToProps = null;
export default connect(mapStateToProps, mapDispatchToProps)(Header);

