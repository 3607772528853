import { DELETE_EVENT, START_EDITING_EVENT, EDIT_EVENT } from "../actionTypes";
import { deleteEvent as deleteService, updateEvent as editService } from "../../../api/events/events.service";

const deleteRequest = ()=>({type: DELETE_EVENT.REQUEST});
const deleteFailure = (error)=>({type: DELETE_EVENT.FAILURE, error})
const deleteSuccess = ()=>({type: DELETE_EVENT.SUCCESS})


export const deleteEvent = (eventId)=>{
    return (dispatch, getState)=>{
        dispatch(deleteRequest())
        return deleteService(eventId)
        .then(response => {
            dispatch(deleteSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(deleteFailure(error))
            return Promise.resolve(false)
        })
    }
}

export const startEditing = (event)=>{
    return {type: START_EDITING_EVENT, event}
}

const editRequest = ()=>({type: EDIT_EVENT.REQUEST})
const editFailure = (error)=>({type: EDIT_EVENT.FAILURE, error})
const editSuccess = ()=>({type: EDIT_EVENT.SUCCESS})

export const editEvent = (payload, eventId)=>{
    return (dispatch, getState)=>{
        dispatch(editRequest())
        return editService(payload, eventId)
        .then(response => {
            dispatch(editSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(editFailure(error))
            return Promise.resolve(false)
        })
    }
}