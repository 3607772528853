import { CHANGE_TAB, FETCH_NEWS, FETCH_NEWS_DETAILS, PUBLISH_NEWS } from "../actionTypes";
import { fetchNews, newsItemService, publishNewsService,divaNewsService } from "../../../api/news/news.service";


const fetchNewsRequest = ()=> ({type: FETCH_NEWS.REQUEST});
const fetchNewsFailure = (error)=> ({type: FETCH_NEWS.FAILURE, error});
const fetchNewsSuccess = (payload)=> ({type: FETCH_NEWS.SUCCESS, payload})

export const getNews = (next,pubId)=>{
    return (dispatch, getState)=>{
        dispatch(fetchNewsRequest())
        return fetchNews(next,pubId)
        .then(response=> {
            dispatch(fetchNewsSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(fetchNewsFailure(error))
            return Promise.resolve(false)
        })
    }
}

const newsItemRequest = ()=> ({type: FETCH_NEWS_DETAILS.REQUEST})
const newsItemSuccess = (payload)=> ({type: FETCH_NEWS_DETAILS.SUCCESS, payload})
const newsItemFailure = (error) => ({type: FETCH_NEWS_DETAILS.FAILULRE, error})

export const newsItemDetails = (id)=>{
    return (dispatch, getState)=>{
        dispatch(newsItemRequest())
        return newsItemService(id)
        .then(response => {
            dispatch(newsItemSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(newsItemFailure(error))
            return Promise.resolve(false)
        })
    }
}


const publishNewsRequest = ()=> ({type: PUBLISH_NEWS.REQUEST})
const publishNewsFailure = (error) => ({type: PUBLISH_NEWS.FAILURE, error})
const publishNewsSuccess = (payload) => ({type: PUBLISH_NEWS.SUCCESS, payload})

export const publish = (id, payload)=>{
    return (dispatch, getState)=>{
        dispatch(publishNewsRequest())
        return publishNewsService(id, payload)
        .then( response => {
            dispatch(publishNewsSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(publishNewsFailure(error))
            return Promise.resolve(false)
        })
    }
}
export const changeTab = (tabId) => ({type: CHANGE_TAB, payload:tabId})