import React, {Component} from "react";
import {VictoryPie,VictoryTooltip} from "victory"

export default class Doughnut extends Component {

    constructor(props){
        super(props)
        this.chartRef = React.createRef();
    }

    componentDidMount(){
    }

    render(){
        const {data} = this.props 
        return (
            <>
                <div ref={this.chartRef}>
                    <VictoryPie
                      labelComponent={<VictoryTooltip
                            style={{ 
                                fill: "#131519",
                                fontSize: '25px',broder:'none' 
                            }}
                            flyoutStyle={{
                                strokeWidth: 0,
                            }}
                        />}
                        colorScale="blue" 
                        data={data}
                       
                        innerRadius={100}
                        labels={({ datum }) => `${datum.x}(${datum.y})`}/>
                </div>
            </>
        );
    }
}