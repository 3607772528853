import {INCIDENTS_REPORT} from "../actionTypes"
import {get} from "../../../api/reports/incidents.service"

export const GRAPH_TYPE = {
    VICTIM : "victim",
    TIME: "time",
    PART_OF_DAY : "pod",
    COUNTY : "county",
    TOWN : "town",
    INCIDENT_TYPE : "incidentType",
    PERPETRATOR_FAMILIARITY : "familiarity",
    PREPETARTOR_RELATION : "relation",
    MOTIVATION : "motivation",
    CONSEQUENCE : "consequence",
    SEEKING_SUPPORT : "seeking-support"
}

const request = (graphType)=>({type: INCIDENTS_REPORT.REQUEST, graphType})
const failure = (error, graphType)=>({type: INCIDENTS_REPORT.FAILURE, error, graphType});
const success = (incidents, graphType,totalCalls,currentIndex)=>({type: INCIDENTS_REPORT.SUCCESS, incidents, graphType,totalCalls,currentIndex})

const createQueryString = (filters)=>{
    if(!filters){
        return ""
    }


   const validFilterKeys =  Object.keys(filters).filter((key)=> filters[key].length > 0)
   let validFilters =  {}
   validFilterKeys.forEach(key => {
    validFilters = {...validFilters, [key] : filters[key]}
   })

   const formatterFilters = Object.keys(validFilters).map((key)=> {
    return `${key}=${validFilters[key].join("|")}`
   })

   const query = formatterFilters.reduce((agg, condition)=> {
       if(agg.length == 0){
           return condition
       }
       return `${agg}&${condition}`
   }, "")

    return query
}

export const getIncidentsReport = (graphType, filters=null,totalCalls,currentIndex)=>{
    return (dispatch, getState)=>{
        dispatch(request(graphType))
        const queryString = createQueryString(filters)
        return get(queryString, graphType)
        .then(result => {
            dispatch(success(result, graphType,totalCalls,currentIndex))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(failure(error, graphType))
            return Promise.resolve(false)
        })
    }
}