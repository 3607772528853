import { connect } from "react-redux";
import NewUser from "./NewUser";
import { functionalRoles } from "../../../redux/actions/userManagement/functionalRoles.action";
import { rolesMaster } from "../../../redux/actions/userManagement/rolesMaster.action";
import { dataGroups } from "../../../redux/actions/userManagement/dataGroups.action";
import { createUser } from "../../../redux/actions/userManagement/createUser.action";
import { editUser } from '../../../redux/actions/userManagement/EditUser.action'

const mapStateToProps = (state) => {
  return {
    roleMasterList: state.rolesMaster.rolesMaster,
    roleMasterloading: state.rolesMaster.loading,
    functionalRolesList: state.functionalRoles.functionalRoles,
    functionalRolesListLoading: state.functionalRoles.loading,
    userTypeloading: state.functionalRoles.loading,
    dataGroupsList: state.dataGroups.dataGroups,
    dataGrouploading: state.dataGroups.loading,
    isEditMode: state.editUser.isEditMode,
    userDetails: state.editUser.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFunctionalRoles: () => {
      return dispatch(functionalRoles());
    },
    getRolesMaster:() => {
      return dispatch(rolesMaster());
    },
    getDataGroups:() => {
      return dispatch(dataGroups());
    },
    add: (payload)=>{
      return dispatch(createUser(payload))
    },
    edit: (payload, uid)=>{
      return dispatch(editUser(payload, uid))
  }
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
