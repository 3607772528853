const createActionTypes = (prefix)=> {
    return ['REQUEST', 'SUCCESS', 'FAILURE', 'CACHED'].reduce((acc, type) => {
      return { ...acc, [type]: `${prefix}_${type}` };
    }, {});
};

/******************* Side Bar ********************/
export const SELECT_MENU_ITEM = "SELECT_MENU_ITEM";

/******************* Reports *********************/
export const GENDER_BASED_INCIDENTS = createActionTypes("GENDER_BASED_INCIDENTS");
export const AGE_BASED_INCIDENTS = createActionTypes("AGE_BASED_INCIDENTS");
export const ETHNICITY_BASED_INCIDENTS = createActionTypes("ETHNICITY_BASED_INCIDENTS");
export const PERPETRATOR_BASED_INCIDENTS = createActionTypes("PERPETRATOR_BASED_INCIDENTS")
export const SEXUAL_ORIENTATION_BASED_INCIDENTS = createActionTypes("SEXUAL_ORIENTATION_BASED_INCIDENTS")
export const TRANS_HISTORY_BASED_INCIDENTS = createActionTypes("TRANS_HISTORY_BASED_INCIDENTS");
export const INCIDENTS_REPORT = createActionTypes("INCIDENTS_REPORT")
export const DEMOGRAPHIC_REPORT = createActionTypes("DEMOGRAPHIC_REPORT")
export const FETCH_INCIDENTS_SUBMITTED_OT = createActionTypes('FETCH_INCIDENTS_SUBMITTED_OT');

/******************** Roles ********************/
export const SET_ROLE = "SET_ROLE"

/******************** Events *******************/
export const FETCH_EVENTS = createActionTypes("FETCH_EVENTS");
export const ADD_EVENT = createActionTypes("ADD_EVENT")
export const PUBLISH_EVENT = createActionTypes("PUBLISH_EVENT")
export const DELETE_EVENT = createActionTypes("DELETE_EVENT")
export const START_EDITING_EVENT = "START_EDITING_EVENT"
export const CANCEL_EDITING_EVENT = "CANCEL_EDITING_EVENT"
export const EDIT_EVENT = createActionTypes("EDIT_EVENT")
export const FETCH_CITIES = createActionTypes("FETCH_CITIES")

/******************** Informations *******************/
export const FETCH_INFORMATIONS = createActionTypes("FETCH_INFORMATIONS");
export const ADD_INFORMATION = createActionTypes("ADD_INFORMATION")
export const PUBLISH_INFORMATION = createActionTypes("PUBLISH_INFORMATION")
export const DELETE_INFORMATION = createActionTypes("DELETE_INFORMATION")
export const START_EDITING_INFORMATION = "START_EDITING_INFORMATION"
export const CANCEL_EDITING_INFORMATION = "CANCEL_EDITING_INFORMATION"
export const EDIT_INFORMATION = createActionTypes("EDIT_INFORMATION")

/******************** Support Services ***********/
export const SUPPORT_SERVICE_TYPE = createActionTypes("SUPPORT_SERVICE_TYPE")
export const COMMUNITIES = createActionTypes("COMMUNITIES")
export const ADD_SUPPORT_SERVICE = createActionTypes("ADD_SUPPORT_SERVICE")
export const FETCH_SUPPORT_SERVICES = createActionTypes("FETCH_SUPPORT_SERVICES")
export const PUBLISH_SUPPORT_SERVICE = createActionTypes("PUBLISH_SUPPORT_SERVICE")
export const DELETE_SUPPORT_SERVICE = createActionTypes("DELETE_SUPPORT_SERVICE")
export const START_EDITING_SUPPORT_SERVICE='START_EDITING_SUPPORT_SERVICE'
export const EDIT_SUPPORT_SERVICE=createActionTypes('EDIT_SUPPORT_SERVICE') 
export const CANCEL_EDIT_SUPPORT_SERVICE = "CANCEL_EDIT_SUPPORT_SERVICE"

/******************** Upload Images ***************/
export const UPLOAD_IMAGE = createActionTypes("UPLOAD_IMAGE");

/********************** Roles**********************/
export const FETCH_USERS = createActionTypes("FETCH_USERS");
export const FETCH_FUNCTIONALROLES = createActionTypes("FETCH_FUNCTIONALROLES");
export const FETCH_ROLESMASTER = createActionTypes("FETCH_ROLESMASTER");
export const FETCH_DATAGROUPS = createActionTypes("FETCH_DATAGROUPS");
export const ADD_USER= createActionTypes("ADD_USER");
export const FETCH_MYRIGHTS= createActionTypes("FETCH_MYRIGHTS");
export const UPDATE_MYRIGHTS = "UPDATE_MYRIGHTS";
export const DELETE_USER= createActionTypes("DELETE_USER");
export const START_EDITING_USER = "START_EDITING_USER";
export const EDIT_USER = createActionTypes("EDIT_USER");
export const CANCEL_EDIT_USER ="CANCEL_EDIT_USER";

/*******************INCIDENTS***********************/
export const FETCH_INCIDENTS=createActionTypes('FETCH_INCIDENTS');
export const FETCH_INCIDENT=createActionTypes('FETCH_INCIDENT');
export const UPDATE_INCIDENT = createActionTypes('UPDATE_INCIDENT');
export const ARCHIVE_INCIDENT = createActionTypes('ARCHIVE_INCIDENT');
export const FETCH_INCIDENTS_STATS = createActionTypes('FETCH_INCIDENTS_STATS');
export const FETCH_ARCHIVED_INCIDENTS = createActionTypes('FETCH_ARCHIVED_INCIDENTS');
export const FETCH_ARCHIVED_INCIDENT = createActionTypes('FETCH_ARCHIVED_INCIDENT');
export const UNARCHIVE_INCIDENT = createActionTypes('UNARCHIVE_INCIDENT');

/************************* NEWS  *********************/
export const FETCH_NEWS = createActionTypes('FETCH_NEWS');
export const FETCH_NEWS_DETAILS = createActionTypes('FETCH_NEWS_DETAILS');
export const PUBLISH_NEWS = createActionTypes('PUBLISH_NEWS');
export const CHANGE_TAB = 'CHANGE_NEWS_TAB';
