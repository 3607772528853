import { useState } from "react"
import styles from "./actions.module.css"
import IMAGES from "../../assets/images/images"
import {ButtonSpinner} from "../loader/Loaders"

export const Chevron = ({onClick=()=>{}})=>{
    return (
        <div onClick={onClick} className={styles.chevronContainer}>
            <img className={styles.chevron}  src={IMAGES.chevron}/>
        </div>
    );
}


export const Publish = ({show, onPublish})=> {

    const [publishing, setPublishing] = useState(false)

    if(!show){
        return null
    }
    if(publishing){
        return <ButtonSpinner />
    }
    return (
        <div 
            className={styles.publish}
            onClick={()=>{
                setPublishing(true)
                onPublish()
                .then(()=>{
                    setPublishing(false)
                })
            }}
        >
                Publish
        </div>
    );
}

export const Delete = ({onClick})=> {
    const [deleting, setDeleting] = useState(false)
    if(deleting){
        return <ButtonSpinner />
    }
    return (
        <div 
            className={styles.delete} 
            onClick={()=>{
                setDeleting(true)
                onClick()
                .then(()=>{
                    setDeleting(false)
                })
            }}>
            <img className={styles.chevron}  src={IMAGES.delete}/>
        </div>
    );
}

export const Edit = ({onClick})=> {
    return (
        <div className={styles.edit} onClick={onClick}>
            <img className={styles.chevron}  src={IMAGES.edit}/>
        </div>
    );
}
