import styles from "./containers.module.css"

export const Title = ({text})=> (<label className={styles.title}>{text}</label>)

export const Item = ({children})=>{
    return  (
        <div className={styles.itemContainer}>
            {children}
        </div>
    );
}

export const DetailsContainer = ({children})=>{
    return (
    <div className={styles.detailsContainer}>
        {children}
    </div>
    );
}

export const ActionContainer = ({children})=>{
    return (
        <div className={styles.actionContainer}>
            {children}
        </div>
    );
}

export const Status = ({status, style})=>{
    
    const statusStyle = style ? `${style} ${styles.statusValue}` : styles.statusValue
    return (
        <div className={styles.statusContainer}>
            <div className={styles.statusTitle}>Status</div>
            <div className={statusStyle}>{status}</div>
        </div>
    )
}