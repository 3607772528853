import React, {Component, useState} from "react";
import styles from "./segmentRadio.module.css"

const Tab = ({title, value, selected, onSelect})=>{

    
    return (
        <div 
            className={selected ? styles.tab : styles.tabSelected}
            onClick={onSelect}>
            {title}
        </div>
    );
}

const SegmentRadio = (props)=>{
    const {options=[], defaultSelected, onSelectOption} = props
    const [selectedIndex, setSelectedIndex] = useState(defaultSelected >= 0 ? defaultSelected : -1 )
    return (
        <div className={styles.container}>
            {
                options.map((option, index)=>{
                    const isSelected = index === selectedIndex ? true : false
                    return <Tab 
                            {...option} 
                            selected={isSelected}
                            onSelect={()=>{
                                if(index !== selectedIndex){
                                    setSelectedIndex(index) // only call api when other tab is selected and not the same one.
                                    onSelectOption(options[index].value)
                                }
                                
                            }}/>
                })
            }
        </div>
    );
}

export default SegmentRadio