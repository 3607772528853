import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TailSpin } from  'react-loader-spinner'
import styles from "./loaders.module.css"

export const TailSpinLoader = ()=>{
    return (
        <TailSpin 
            wrapperClass={styles.tailspin}  
            height={50} 
            width={50} 
            color={"#100045"}
            radius={0}/>
    );
}


export const SectionLoader = ()=>{
    return (
        <div className={styles.section}>
            <TailSpinLoader />
        </div>
    );
}

export const ButtonSpinner = ()=>{
    return (
        <TailSpin 
            wrapperClass={styles.tailspin}  
            height={30} 
            width={30} 
            color={"#100045"}
            radius={0}/>
    );
}