import { FETCH_INCIDENT } from "../../actions/actionTypes";

const initialState = {
  incident:null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INCIDENT.REQUEST:
      return { ...state, loading: true };
    case FETCH_INCIDENT.FAILURE:
      return { ...state, loading: false };
    case FETCH_INCIDENT.SUCCESS:
      return { ...state, incident: action.incident, loading: false };
    default:
      return state;
  }
};

export default reducer;