import {ERRORS} from './constants';

const FIREBASE_ERROR_CODES = {
  MFA_REQUIRED : "multi-factor-auth-required",
  MISSING_OTP : "auth/missing-code",
  RECENT_LOGIN_REQUIRED: "auth/requires-recent-login"
}

export function getErrorMessage(errorCode) {
    let _error;
    switch (errorCode) {
      case ERRORS.TOO_MANY_REQUEST:
        _error = "Please Wait a Few Minutes Before You Try Again.";
        break;
      case ERRORS.USER_NOT_FOUND:
      case ERRORS.WRONG_PASSWORD:
      case ERRORS.INVALID_EMAIL:
        _error = "Invalid username or password. Please try again.";
        break;      
      case ERRORS.INTERNAL_ERROR:
        _error = "Invalid Email and Password.";
        break;
      case FIREBASE_ERROR_CODES.MFA_REQUIRED:
        _error = "Multifactor authentication required"
        break;
      case FIREBASE_ERROR_CODES.MISSING_OTP:
        _error = "OTP is required to login"
        break;
      case FIREBASE_ERROR_CODES.RECENT_LOGIN_REQUIRED:
        _error = "Click on logout button and try signing in again."
        break;
      default:
        _error = "Error, Please Try Again.";
    }
    return _error;
  }