import {connect} from "react-redux"
import Incident from "./Incident";
import {getIncident,updateReviewStatus, archive} from "../../../redux/actions/incidents/incident.action"

const mapStateToProps = (state)=>{
    return {
        incidentData:state.incidents.incident
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        get: (incident_id)=>{
            return dispatch(getIncident(incident_id))
        },
        markedAsReviewd: (payload,incident_id)=>{
            return dispatch(updateReviewStatus(payload,incident_id))
        },
        archive: (id)=>{
            return dispatch(archive(id))
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Incident);

