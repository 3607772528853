import { BASE_URL,POST, PATCH, DELETE, GET, SERVICES } from "../constants";
import {statusParsing, jsonParsing} from "../utils";
import {getAccessToken} from "../../authActions/authActions";

export const post = async(payload)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${SERVICES}`
    
    return fetch(resource, {
        method: POST,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}` 
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const patch = async(payload, serviceId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${SERVICES}/${serviceId}`
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const deleteService = async(serviceId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${SERVICES}/${serviceId}`
    return fetch(resource, {
        method: DELETE,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}