import { FETCH_USERS } from "../actionTypes";
import { fetchUsers } from "../../../api/userManagement/userManagement.service";

const request = () => ({ type: FETCH_USERS.REQUEST });
const failure = (error) => ({ type: FETCH_USERS.FAILURE, error });
const success = (obj) => ({ type: FETCH_USERS.SUCCESS, obj });

export const getUsers = (isLoadMore,next) => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchUsers(next)
      .then((res) => {
        let obj={
          'data':res,
          'isLoadMore':isLoadMore
      }
        return dispatch(success(obj));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };
};
