import { connect } from "react-redux"
import ImageField from "./image-field"
import {uploadImage} from "../../redux/actions/imageUploader/images.actions"


const mapStateToProps = (state)=>{
    return {
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        upload : (payload)=>{
            return dispatch(uploadImage(payload))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageField);