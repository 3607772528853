import {combineReducers} from "redux";
import selectedMenu from "./sidebar/sidebar.reducer";
import reports from "./reports/index.reducer";
import role from "./userManagement/roles.reducer"
import events from "./events/events.reducer";
import informations from "./informations/informations.reducer"
import information from "./informations/information.reducer"
import addInformation from "./informations/addInformation.reducer"
import newEvent from "./events/addEvent.reducer";
import event from "./events/event.reducer";
import getUsers from "./userManagement/getUsers.reducer";
import functionalRoles from "./userManagement/functionalRoles.reducer";
import supportServices from "./supportServices/index.reducer"
import rolesMaster from "./userManagement/rolesMaster.reducer";
import dataGroups from "./userManagement/dataGroups.reducer";
import myRights from "./userManagement/myRights.reducer";
import incidents from "./incidents/index.reducer";
import editUser from "./userManagement/editUser.reducer"
import news from "./news/news.reducer"

export default combineReducers({
    selectedMenu,
    reports,
    role,
    events,
    newEvent,
    event,
    supportServices,
    getUsers,
    functionalRoles,
    rolesMaster,
    dataGroups,
    myRights,
    incidents,
    editUser,
    news,
    informations,
    addInformation,
    information
})