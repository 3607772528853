import { BASE_URL, GET, INFORMATIONS, POST, PUT, DELETE,PATCH} from "../constants";
import {statusParsing, jsonParsing} from "../utils";
import {getAccessToken} from "../../authActions/authActions";

export const fetchInformation = async(next)=>{
    const token = await getAccessToken();
    let resource = `${BASE_URL}${INFORMATIONS}`
    if(next){
        resource=`${resource}?next=${next}`
    }
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type" : "application/json",
            "userauth": `bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const addInformation = async(payload)=>{ //api call function.
    const token = await getAccessToken();
    const resource = `${BASE_URL}${INFORMATIONS}`
    
    return fetch(resource, {  //api call
        method: POST,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}` 
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const updateInformation = async(payload, informationId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${INFORMATIONS}/${informationId}`
    return fetch(resource, {
        method: PUT,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}


export const deleteInformation = async(informationId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${INFORMATIONS}/${informationId}`
    return fetch(resource, {
        method: DELETE,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const publishInformation = async(payload, informationId)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${INFORMATIONS}/${informationId}`
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type" : "application/json",
            "userauth":`bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}

