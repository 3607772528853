import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  multiFactor,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence
} from "firebase/auth";
import {getErrorMessage} from '../utils/errors'
import {app} from "../configuration/gcp-identity-platform-config"
import {showError, showSuccess} from "../utils/toasts"

export const SIGN_IN_STATUS = {
  MFA_DISABLED : "mfa-disabled",
  UNVERIFIED_EMAIL: "verify-email",
  SUCESS: "success"
}


export const complete2FAEnrollment = async (verifyId, OTP, deviceName)=>{
  const auth = getAuth(app)
  const multiFactorUser =  multiFactor(auth.currentUser)
   // Ask user for the verification code.
   let cred = PhoneAuthProvider.credential(verifyId, OTP);
   let multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
   // Complete enrollment.
   return await multiFactorUser.enroll(multiFactorAssertion, deviceName);
}

export const enableRecaptcha = ()=>{
  try {
    const auth = getAuth(app)
    const element = document.getElementById('captcha-container');
    const isDisabled = element.disabled;

    if(!window.recaptchaVerifier){
      window.recaptchaVerifier = new RecaptchaVerifier(
      'captcha-container',
      // Optional reCAPTCHA parameters.
      {
        'size': 'invisible',
        'callback': function(response) {
          // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
          // ...
          // signInWithPhoneCode(response)
        },
        'expired-callback': function() {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        }
      },
      auth)
    
      window.recaptchaVerifier.render().then(function(widgetId) {
        window.recaptchaWidgetId = widgetId;
        element.disabled = isDisabled;
      });
    }
  } catch (error) {
  }
}

export const enrollSecondFactor= async (phone)=>{
  try {
    const auth = getAuth(app)
    const verificationId =  await multiFactor(auth.currentUser)
    .getSession()
    .then(session=>{
      // Specify the phone number and pass the MFA session.
      var phoneInfoOptions = {
        phoneNumber: phone,
        session: session
      };

      let phoneAuthProvider = new PhoneAuthProvider(auth);
      // Send SMS verification code.
      return phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions, window.recaptchaVerifier);
    })
    return verificationId
  } catch (error) {
    throw error
  }
}

export function signIn(_email, _password) {
  return new Promise((resolve, reject) => {
    try {
      if(!window.recaptchaVerifier){
        const auth = getAuth(app)
  
        window.recaptchaVerifier = new RecaptchaVerifier(
        'captcha-container',
        // Optional reCAPTCHA parameters.
        {
          'size': 'invisible',
          'callback': function(response) {
            // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
            // ...
            // signInWithPhoneCode(response)
          },
          'expired-callback': function() {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          }
        },
        auth)
      
        window.recaptchaVerifier.render().then(function(widgetId) {
          window.recaptchaWidgetId = widgetId;
        });
        
      }
    } catch (error) {
    }
    
    var resolver;
    const auth = getAuth(app);

    /**
     * Saving the Auth state in session storage
     * 
     * UserStory ID: 205691
     * https://oitcolvsts.visualstudio.com/Zoteria/_workitems/edit/205691
     */
    setPersistence(auth, browserSessionPersistence)
    .then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // New sign-in will be persisted with session persistence.
      
      //Signin with Email and Password
      signInWithEmailAndPassword(auth, _email, _password)
      .then((userCredential) => {
        // Signed in
        const {emailVerified} = userCredential.user;
        const resolveStatus = emailVerified ? SIGN_IN_STATUS.MFA_DISABLED : SIGN_IN_STATUS.UNVERIFIED_EMAIL
        resolve(resolveStatus);
      })
      .catch((error) => {

        if(error.code === "auth/multi-factor-auth-required"){
          resolver = getMultiFactorResolver(auth, error)          
          var phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
          };

          var phoneAuthProvider = new PhoneAuthProvider(auth);
        // Send SMS verification code
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
          .then(function(verificationId) {
            // Ask user for the SMS verification code.
            const promptMessage = `Please enter the code sent on ${resolver.hints[0].phoneNumber} (${resolver.hints[0].displayName}), via SMS.`
            const verificationCode = window.prompt(promptMessage)
            var cred = PhoneAuthProvider.credential(
                verificationId, verificationCode);
            var multiFactorAssertion =
                PhoneMultiFactorGenerator.assertion(cred);
            // Complete sign-in.
            return resolver.resolveSignIn(multiFactorAssertion)
          })
          .then(function(userCredential) {
            // User successfully signed in with the second factor phone number.
            resolve(SIGN_IN_STATUS.SUCESS)
          })

          .catch(error => {
            const errorMsg = getErrorMessage(error.code);
            reject(errorMsg);
          })
        }else{
          const errorCode=error.code.split('/')[1];
          const errorMsg=getErrorMessage(errorCode);
          reject(errorMsg);
        }
        
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      showError(errorMessage,'top-center');
    });
  });
}

export function logOut() {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject();
      });
  });
}

export async function getAccessToken() {
  const auth = getAuth(); 
  const user = auth.currentUser;
  if (user) {
    const token = await getIdToken(user);
    return token;
  } else {
    return "";
  }
}

export const resetPassword = (email)=>{
  const auth = getAuth();
  sendPasswordResetEmail(auth,email)
  .then(() => {
    showSuccess('Password reset email sent. Please inform user to reset password within 24 hours.');
  })
  .catch((error) => {
    showError(error.code,'top-center');
  });
}

