import { useEffect } from "react";
import IMAGES from "../../assets/images/images";
import { TailSpinLoader } from "../loader/Loaders";
import styles from "./contentImage.module.css";
import { useState } from "react";

export const ContentImage = ({ url }) => {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(!url);

  useEffect(() => {
    setLoading(Boolean(url));
  }, [url]);

  return (
    <div style={{ position: "relative" }}>
      <img
        onLoad={() => {
          setLoading(false);
          setError(false);
        }}
        onError={() => {
          setLoading(false);
          setError(true);
        }}
        className={styles.image}
        src={url}
        style={{ opacity: !loading && !error ? 1 : 0 }}
      />

      {loading && (
        <div
          style={{
            position: "absolute",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TailSpinLoader />
        </div>
      )}

      {!loading && (error || !url) && (
        <div
          style={{
            position: "absolute",
            inset: 0,
            // backgroundColor:"rgb(196, 194, 194)",
            backgroundImage: `url("${IMAGES.no_image}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: "10px",
          }}
        ></div>
      )}
    </div>
  );
};

export const DefaultContentImage = ({ image }) => {
  return <img className={styles.image} src={image} />;
};
