import { BASE_URL, GET, NEWS, PUT,PATCH} from "../constants";
import {statusParsing, jsonParsing} from "../utils";
import {getAccessToken} from "../../authActions/authActions";

export const fetchNews = async (next,pubId)=>{
    const token = await getAccessToken();
    let resource = new URL(`${BASE_URL}${NEWS}`)
    if(next){
       resource.searchParams.set("next",next)
    }
    if(pubId){
        resource.searchParams.set("pubId",pubId)
    }
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type" : "application/json",
            "userauth": `bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const newsItemService = async (id)=>{
    const token = await getAccessToken();
    let resource = `${BASE_URL}${NEWS}/${id}`
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type" : "application/json",
            "userauth": `bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const publishNewsService = async (id, payload)=>{
    const token = await getAccessToken();
    let resource = `${BASE_URL}${NEWS}/${id}`
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type" : "application/json",
            "userauth": `bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}
