import { ADD_INFORMATION } from "../../actions/actionTypes";

const initialState = {
    loading: false
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case ADD_INFORMATION.REQUEST:
            return {...state, loading: true}
        case ADD_INFORMATION.SUCCESS:
        case ADD_INFORMATION.FAILURE:
            return {...state, loading: false}
        default:
            return state
    }
}

export default reducer;