import { FETCH_DATAGROUPS } from "../actionTypes";
import { fetchDataGroup } from "../../../api/userManagement/userManagement.service";

const request = () => ({ type: FETCH_DATAGROUPS.REQUEST });
const failure = (error) => ({ type: FETCH_DATAGROUPS.FAILURE, error });
const success = (dataGroups) => ({ type: FETCH_DATAGROUPS.SUCCESS, dataGroups});

export const dataGroups = () => {
  return (dispatch, getState) => {
    dispatch(request());
    return fetchDataGroup()
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(failure(error));
        return Promise.resolve(false)
      });
  };
};
