import { DELETE_INFORMATION, START_EDITING_INFORMATION, EDIT_INFORMATION } from "../actionTypes";
import { deleteInformation as deleteService, updateInformation as editService } from "../../../api/information/information.service";

const deleteRequest = ()=>({type: DELETE_INFORMATION.REQUEST});
const deleteFailure = (error)=>({type: DELETE_INFORMATION.FAILURE, error})
const deleteSuccess = ()=>({type: DELETE_INFORMATION.SUCCESS})


export const deleteInformation = (informationId)=>{  //handle by thunk
    return (dispatch, getState)=>{
        dispatch(deleteRequest())          // main store
        return deleteService(informationId)  // delete call on going
        .then(response => {
            dispatch(deleteSuccess(response)) //redux store confirms 
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(deleteFailure(error))
            return Promise.resolve(false)
        })
    }
}

export const startEditing = (information)=>{
    return {type: START_EDITING_INFORMATION, information}
}

const editRequest = ()=>({type: EDIT_INFORMATION.REQUEST})
const editFailure = (error)=>({type: EDIT_INFORMATION.FAILURE, error})
const editSuccess = ()=>({type: EDIT_INFORMATION.SUCCESS})

export const editInformation = (payload, informationId)=>{
    return (dispatch, getState)=>{
        dispatch(editRequest())
        return editService(payload, informationId)
        .then(response => {
            dispatch(editSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(editFailure(error))
            return Promise.resolve(false)
        })
    }
}