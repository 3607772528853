import { SET_ROLE } from "../../actions/actionTypes";

const initialState = null;

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case SET_ROLE:
            return action.role;
        default:
            return state
    }
}

export default reducer;