import {connect} from "react-redux"
import ArchivedIncidents from "./ArchivedIncidents"
import {getArchivedIncidents} from "../../../redux/actions/incidents/incidents.action"


const mapStateToProps = (state)=>{
    return {
        incidents : state.incidents.archivedIncidents.data,
        next: state.incidents.archivedIncidents.next
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
       getIncidents : (next, shouldLoadMore=false)=>{
            return dispatch(getArchivedIncidents(next, shouldLoadMore))
       } 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedIncidents)