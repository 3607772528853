import {COMMUNITIES} from "../../actions/actionTypes"

const reducer = (state=[], action)=>{
    switch(action.type){
        case COMMUNITIES.SUCCESS:
            return action.communities;
        default:
            return state
    }
}

export default reducer;