import { FETCH_INFORMATIONS } from "../actionTypes";
import { fetchInformation } from "../../../api/information/information.service";

const request = ()=>({type: FETCH_INFORMATIONS.REQUEST});
const failure = (error)=>({type: FETCH_INFORMATIONS.FAILURE, error})
const success = (obj)=>({type: FETCH_INFORMATIONS.SUCCESS, obj})


export const getInformations = (isLoadMore,next)=>{ //main information
    return (dispatch, getState)=>{
        dispatch(request())  //4
        return fetchInformation(next) //main api call
        .then(informations => {
            let obj={
                'data':informations,
                'isLoadMore':isLoadMore //payload
            }
            dispatch(success(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(failure(error))
            return Promise.resolve(false)
        })
    }
}