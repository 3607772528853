import { connect } from "react-redux";
import NewService from "./NewService";
import {supportServiceTypes} from "../../../redux/actions/supportServices/serviceTypes.actions"
import {communities} from "../../../redux/actions/supportServices/communities.actions"
import {addSupportService,editSupportService} from "../../../redux/actions/supportServices/supportService.action"
import { dataGroups } from "../../../redux/actions/userManagement/dataGroups.action";

const mapStateToProps = (state)=>{
    return {
        types: state.supportServices.serviceTypes,
        communities : state.supportServices.communities, 
        isEditMode: state.supportServices.service.isEditMode,
        details: state.supportServices.service.details,
        dataGroupsList: state.dataGroups.dataGroups,
        myRights:state.myRights.myRights,
        communities_map:(state.supportServices.communities).reduce((agg, comm)=>  ({...agg, [comm.value] : comm.label}), {})
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getServiceTypes : ()=>{
            return dispatch(supportServiceTypes())
        },
        getCommunities : ()=>{
            return dispatch(communities())
        },
        add: (payload)=>{
            return dispatch(addSupportService(payload))
        },
        edit: (payload, id)=>{
            return dispatch(editSupportService(payload, id))
        },
        getDataGroups:() => {
            return dispatch(dataGroups());
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewService);