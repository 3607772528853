const Statistics = ({data})=>{
    const shrunkedStyle = data > 999 ? styles.shrunkedLabel : {}
    return (
        <div style={styles.container}>
            <label style={{...styles.label, ...shrunkedStyle}}>{data}</label>
        </div>
    );
}


const styles = {
    container : {
        display : "flex",
        flexDirection : "column",
        flex : 1,
        alignItems : "center",
        justifyContent : "center"
    },
    label : {
        fontSize : 124,
        fontWeight: "900",
        color : "#100045"
    },
    shrunkedLabel : {
        fontSize : 90,
    }
}

export default Statistics