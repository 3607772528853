import Switch from "react-switch";
import styles from "./switch.module.css"

const FlipSwitch = ({style="", onChange, checked})=>{

    return (
        <Switch 
            className={style} 
            onChange={onChange} 
            checked={checked}
        />
    );
}

const AMPMSwitch = ({style="", onChange, value="am"})=>{
    const HC = ({children})=>{
        return (
            <div className={styles.ampmHandle}>
                {children}
            </div>
        );
    }

    const VC = ({children})=>{
        return (
            <div className={styles.ampmValue}>
                {children}
            </div>
        );
    }
    return (
        <Switch 
            className={style}
            height={40}
            width={70}
            borderRadius={3}
            activeBoxShadow="0px 0px 1px 2px #fffc35"
            onChange={(checked)=>{
                onChange(checked ? "am" : "pm")
            }}
            // offColor={"#999"}
            offColor={"#100045"}
            offHandleColor={"#fff"}
            onColor={"#100045"}
            // onColor={"#999"}
            onHandleColor={"#fff"}
            checked={value === "am" ? true : false}
            checkedIcon={<VC>AM</VC>}
            uncheckedIcon={<VC>PM</VC>}
            // checkedHandleIcon={<HC>☀️</HC>}
            // uncheckedHandleIcon={<HC>🌛</HC>}
            checkedHandleIcon={<HC></HC>}
            uncheckedHandleIcon={<HC></HC>}
        />
    );
}  

const PrimarySwitch = ({style,...props}) => {
  return (
    <label>
      <div className= {`${styles.label} ${style}`}>{props.label}</div>
      <Switch
        onChange={props.onChange}
        checked={props.isChecked}
        id={props.id}
        disabled={props.isDisabled}
      />
    </label>
  );
};

export {FlipSwitch as Switch , AMPMSwitch,PrimarySwitch};