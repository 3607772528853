import {COMMUNITIES} from "../actionTypes"
import {get} from "../../../api/supportServices/communities.service"

const request = ()=>({type: COMMUNITIES.REQUEST})
const failure = (error)=>({type: COMMUNITIES.FAILURE, error})
const success = (communities)=>({type: COMMUNITIES.SUCCESS, communities})

export const communities = ()=>{
    return (dispatch, getState)=>{
        dispatch(request())
        return get()
        .then(response=>{
            dispatch(success(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(failure(error))
            return Promise.resolve(false)
        })
    }
}