import {ADD_SUPPORT_SERVICE,DELETE_SUPPORT_SERVICE,PUBLISH_SUPPORT_SERVICE,START_EDITING_SUPPORT_SERVICE,EDIT_SUPPORT_SERVICE} from "../actionTypes"
import {patch as updateService, deleteService,post as addService} from "../../../api/supportServices/supportService.service"

const addRequest = ()=>({type: ADD_SUPPORT_SERVICE.REQUEST})
const addFailure = (error)=>({type: ADD_SUPPORT_SERVICE.FAILURE, error})
const addSuccess = ()=>({type: ADD_SUPPORT_SERVICE.SUCCESS})

export const addSupportService = (payload)=>{
    return (dispatch, getState)=>{
        dispatch(addRequest())
        return addService(payload)
        .then(response=>{
            dispatch(addSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(addFailure(error))
            return Promise.resolve(false)
        })
    }
}

const updateRequest = ()=>({type: PUBLISH_SUPPORT_SERVICE.REQUEST})
const updateFailure = (error)=>({type: PUBLISH_SUPPORT_SERVICE.FAILURE, error})
const updateSuccess = (service)=>({type: PUBLISH_SUPPORT_SERVICE.SUCCESS, service})

export const updateSupportService = (payload, serviceId)=>{
    return (dispatch, getState)=>{
        dispatch(updateRequest())
        return updateService(payload, serviceId)
        .then(res => {
            dispatch(updateSuccess(res))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(updateFailure(error))
            return Promise.resolve(false)
        })
    }
}

const deleteRequest = ()=>({type: DELETE_SUPPORT_SERVICE.REQUEST})
const deleteFailure = (error)=>({type: DELETE_SUPPORT_SERVICE.FAILURE, error})
const deleteSuccess = ()=>({type: DELETE_SUPPORT_SERVICE.SUCCESS})

export const deleteSupportService = (serviceId)=>{
    return (dispatch, getState)=>{
        dispatch(deleteRequest())
        return deleteService(serviceId)
        .then(response => {
            dispatch(deleteSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(deleteFailure(error))
            return Promise.resolve(false)
        })
    }
}
export const startEditing = (service)=>{
    return {type: START_EDITING_SUPPORT_SERVICE, service}
}

const editRequest = ()=>({type: EDIT_SUPPORT_SERVICE.REQUEST})
const editFailure = (error)=>({type: EDIT_SUPPORT_SERVICE.FAILURE, error})
const editSuccess = ()=>({type: EDIT_SUPPORT_SERVICE.SUCCESS})

export const editSupportService = (payload, id)=>{
    return (dispatch, getState)=>{
        dispatch(editRequest())
        return updateService(payload, id)
        .then(response => {
            dispatch(editSuccess())
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(editFailure(error))
            return Promise.resolve(false)
        })
    }
}
