import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {MAP_API_KEY} from "../../../../config";


const dropdownStyle = {
    control: (provided, state)=> {
        const {isDisabled} = state
        return (
            {...provided, backgroundColor: isDisabled ? "#999" : "#efefef", borderRadius: 5, height: 44, borderWidth: 1, borderColor: "#C5C1C8", marginBottom: 20,width:"600px"}
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#100045", fontFamily: "Moderat-Regular", fontSize: 13}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#837B8B", fontFamily: "Moderat-Regular", fontSize: 13}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    container: (provided, state)=> ({...provided, width: 515,}),
    input : (provided, state)=> ( { ...provided,
        backgroundColor: "transparent", color: "#100045", fontFamily: "Moderat-Regular", fontSize: 13
    })

}

export const LocationAutocomplete = ({field, form,defaultInputValue, placeholder=""})=>{
    return (
        <GooglePlacesAutocomplete
            //apiKey={MAP_API_KEY}
            autocompletionRequest={{
                componentRestrictions: {
                    country: ['uk','cz','al'], //resitricted to uk only. To be changed as per user's country
                }
            }} 
            onLoadFailed={(error)=>{
                
            }}
    
            selectProps={{
                defaultInputValue:defaultInputValue?.name,
                styles : dropdownStyle,
                placeholder :  placeholder,
                blurInputOnSelect: true,
                onChange : (result)=>{
                    form.setFieldValue(field.name, {name: result.label, id: result.value.place_id})
                    geocodeByPlaceId(result.value.place_id)
                    .then(results => {
                        form.setFieldValue("address", results[0].formatted_address)
                        let geometry = null
                        if(results[0].geometry){
                            const {location, viewport : vp} =  results[0].geometry
                            geometry =  {
                                lat : location.lat(),
                                lng : location.lng()
                            }
                            form.setFieldValue("geometry", geometry)
                        }
                    })
                    .catch(error => {
                        console.error("error while geoding", error)
                    });
                }
            }}
        />
    );
}