/** *****************************Actions************************* */
export const Action = {
  CREATE: "create",
  LIST: "list",
  GET: "get",
  UPDATE: "update",
  DELETE: "delete",
  ADD: "add",
  PUBLISH: "publish",
  DOWNLOAD: "download",
  REVIEW:'review',
  ARCHIVE: 'archive'
};

/** *****************************Resources************************* */
export const Resource = {
  IAM: "iam",
  INCIDENT: "incident",
  EVENT: "event",
  SERVICES: "service",
  USERS: "users",
  INFORMATION: "information",
  ARTICLE: "article",
  FEED: "feed",
  RIGHTS: "rights",
  ROLE: "role",
  STATS: "stats",
  REPORTS: "reports",
  ROLES: "roles",
  DATAGROUPS:'dataGroups',
};
