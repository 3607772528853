import {SELECT_MENU_ITEM} from "../../actions/actionTypes"
import {SIDE_BAR_MENU_ITEMS} from "../../../utils/constants"

const initialState = SIDE_BAR_MENU_ITEMS.REPORTS;

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case SELECT_MENU_ITEM:
            return action.item
        default:
            return state
    }
}

export default reducer;