import {connect} from "react-redux"
import NewInformation from "./NewInformation"
import {addInformation} from "../../../redux/actions/informations/newInformation.actions"
import {editInformation} from "../../../redux/actions/informations/information.actions"
import { dataGroups } from "../../../redux/actions/userManagement/dataGroups.action";

const mapStateToProps = (state)=>{ 
    return {
        submitting: state.addInformation.loading, 
        isEditMode: state.information.isEditMode, 
        details: state.information.details,
        dataGroupsList: state.dataGroups.dataGroups,
        myRights:state.myRights.myRights
    }
}

const mapDispatchToProps = (dispatch)=>{     
    return {
        newInformation: (payload)=>{ 
            return dispatch(addInformation(payload))
        },
        edit: (payload, id)=>{     
            return dispatch(editInformation(payload, id))
        },
        getDataGroups:() => {  
            return dispatch(dataGroups()); 
        },
		
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInformation); 